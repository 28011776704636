import { Checkbox, Form, Input, Tooltip } from 'antd'
import React, { useState } from 'react'
import { Loading } from '../../Common'
import { EmptyData } from '../../Common/components/EmptyData'
import { useDispatch, useSelector } from 'react-redux';
import { documentAction } from '../../DocumentSection/state/actions';

export default function SecondStepMLSModal({data, setData, form, isMLSVisible}) {
    const [toggleId, setToggleId] = useState(null);
    const [checkedValues, setCheckedValues] = useState([]);
    let { getPropertySearchData, getPropertySearchLoading } = useSelector((state) => state.documentSection.PropertySearchReducer,);
    const dispatch = useDispatch();

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    let formHandleSubmit = (ele) => {
        const filteredData = Object.keys(ele).reduce((acc, key) => {
            if (ele[key] !== undefined && ele[key] !== null && ele[key] !== "") {
            acc[key] = ele[key];
            }
            return acc;
        }, {});

        const matchingIds = [];
         data?.selectedMlsType?.forEach((ele) => {
            matchingIds.push({
                mlsAgentId: ele.id,
                mlsType: ele.value
            })
        });
        const payload = {
            ...filteredData,
            mlsAgentData: matchingIds,
            skip: 0,
            limit: 5
        };
        dispatch(documentAction.getMLSPropertySearch(payload, 0, 5));
        form.resetFields();
    };

    let Mlslogos = [
        {
        type: "RECOLORADO",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage82704",
        },
        {
        type: "IRES",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage84057",
        },
        {
        type: "PPAR",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/657ff2157e660a0701cfaa24/MEMORIES/19PPMLS-logo-MAIN-WebRGB.jpeg",
        },
        {
        type: "PAR",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/qa/6444b9c521382a6780572f63/MEMORIES/2pastedImage68271",
        },
        {
        type: "GJARA",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/prod/64cb4128b00a232d9c2cc0d4/MEMORIES/29gjara-logo.png",
        },
    ];

    const getImageSrc = (propertyType) => {
        const image = Mlslogos.find((img) => propertyType.includes(img.type));
        return image ? image.src : "";
    };

    const handleSelectAll = (e, ele) => {
        const allChecked = e.target.checked;
        let newArr =[];

        const fillData = [];
        if (allChecked) {
            ele.map((el,i)=>{
                return newArr.push(String(i));
            });

            ele.map((ele,i) => {
            return fillData.push({
                    mlsType: ele.propertyType[0],
                    address: ele.fullAddress,
                    thirdpartyId: ele.thirdpartyId,
                });
            });
        }
        setCheckedValues(newArr);
        setData({
            ...data,
            selectedProperty: fillData
        });
    };

    const onChangeCheckBox = (checkedValues) => {
        setCheckedValues(checkedValues);
        const fillData = [];
        let filterData = checkedValues.map((index) => getPropertySearchData[index]);
        if (filterData) {
            filterData.map((ele) => {
            return fillData.push({
                    mlsType: ele.propertyType[0],
                    address: ele.fullAddress,
                    thirdpartyId: ele.thirdpartyId,
                });
            });
        }
        setData({
            ...data,
            selectedProperty: fillData
        });
    };
const { StreetNumber, StreetName, UnitLabel, UnitNumber, City } = isMLSVisible?.defaultAddress || {};
    return (
        <>
            {getPropertySearchData &&
            getPropertySearchData.length === 0 &&
            !getPropertySearchLoading ? (
            <div>
                <Form
                    form={form}
                    {...layout}
                    className="formaccnt"
                    name="nest-messages"
                    onFinish={(ele) => formHandleSubmit(ele)}
                    initialValues={{
                        streetNumber: StreetNumber || "",
                        streetName: StreetName || "",
                        unit: UnitLabel && UnitNumber ? `${UnitLabel} ${UnitNumber}` : String(UnitNumber || ""),
                        city: City || "",
                    }}
                    preserve={true}
                    style={{ minHeight: "402px" }}
                >
                    <Form.Item
                        name="streetNumber"
                        label="Street Number"
                        className="lbl"
                        rules={[
                        {
                            required: toggleId ? false : true,
                            message: "Please Enter Street Number",
                        },
                        ]}
                    >
                        <Input
                            onFocus={() => setToggleId(false)}
                            placeholder="Please Enter Street Number"
                        />
                    </Form.Item>
                    <Form.Item
                        name="streetName"
                        label="Street Name"
                        className="lbl"
                        rules={[
                            {
                                required: toggleId ? false : true,
                                message: "Please Enter Street Name",
                            },
                        ]}
                    >
                        <Input
                            onFocus={() => setToggleId(false)}
                            placeholder="Please Enter Street Name"
                        />
                    </Form.Item>
                    <Form.Item name="unit" label="Unit" className="lbl">
                        <Input placeholder="Please Enter Unit" />
                    </Form.Item>
                    <Form.Item className="lbl1" name="city" label="City">
                        <Input placeholder="Please Enter City Name" />
                    </Form.Item>
                    <h2
                        style={{
                        margin: "-15px 0 15px 0",
                        textAlign: "center",
                        color: "gray",
                        }}
                    >
                        OR
                    </h2>
                    <Form.Item
                        className="lbl"
                        name="listingId"
                        label="Listing Id"
                        rules={[
                        { required: toggleId, message: "Please Enter Listing Id" },
                        ]}
                    >
                        <Input
                        style={{ width: "100%" }}
                        placeholder="Please Enter Listing Id"
                        onFocus={() => setToggleId(true)}
                        />
                    </Form.Item>
                </Form>
            </div>
            ) : (
            <div>
                {getPropertySearchData?.length > 0 ? (
                <div style={{height: "100%", width: "100%" }}>
                    {
                    <Checkbox
                        style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        marginTop: "-4rem",
                        marginBottom: "0px",
                        }}
                        onChange={(e) => handleSelectAll(e, getPropertySearchData)}
                        checked={data.selectedProperty.length === getPropertySearchData.length}
                        value={checkedValues}
                    >
                        Select All
                    </Checkbox>
                    }
                    <div
                    style={{
                        padding: "1rem 0rem 1rem 1rem",
                        height: "52vh",
                        overflowY: "auto",
                        width: "100%",
                    }}
                    >
                    {
                        <Checkbox.Group
                        style={{ display: "flex", flexDirection: "column",marginLeft:"-15px" }}
                        options={getPropertySearchData?.map((option, index) => ({
                            label: (
                            <div
                                style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "15px",
                                marginBottom: "10px",
                                boxSizing: "border-box",
                                width: "100%",
                                boxShadow:
                                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                                border: "1px solid grey",
                                borderRadius: "5px",
                                padding: "5px",
                                overflowX: "hidden",

                                }}
                            >
                                <div style={{ lineHeight: "17px",display:"flex",justifyContent: "space-between"}}>
                                    <div
                                    style={{
                                        fontWeight: "bold",
                                        color: "rgba(0, 0, 0, 0.45)",
                                        display:"flex",
                                        flexDirection:"column"
                                    }}
                                    >
                                    <span style={{fontSize: "17px",color:"rgba(0, 0, 0, 0.7)"}}>{option?.fullAddress}</span>
                                    <span>
                                        Seller Name: {option?.sellerAgentName}
                                    </span>
                                    <span>
                                        Seller Email: {option?.sellerAgentEmail}
                                    </span>
                                    </div>
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <Tooltip title={option?.propertyType?.includes("RECOLORADO") ? "REcolorado" : option?.propertyType} trigger={'hover'}>
                                        <img height="30px" width="80px" style={{objectFit:"contain"}} src={getImageSrc(option?.propertyType)} alt="logo" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            ),
                            value: index.toString(),
                        }))}
                            value={checkedValues}
                            onChange={onChangeCheckBox}
                        />
                    }
                    </div>
                </div>
                ) : (
                <div
                    style={{
                    display: "flex",
                    margin: "auto",
                    height: "350px",
                    width: "300px",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    }}
                >
                    {getPropertySearchLoading ? <Loading /> : <EmptyData />}
                </div>
                )}
            </div>
            )}
        </>
    )
}
