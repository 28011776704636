import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Upload, message, Button, Typography, Image } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { InboxOutlined } from "@ant-design/icons";
import { clientAction } from "../state/actions";
const { Dragger } = Upload;
const { Text } = Typography;

function ImageUploadDetails({
  data,
  selectedClient,
  setModelDrawer,
  section,
  supportedFormats,
  documentaryId,
  sourcePlace,
  forProfileClient
}) {
  const dispatch = useDispatch();
  const [fileText, setFileText] = useState({ name: null, size: null });
  const [loading, setLoading] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  console.log("previewUrl", {previewUrl});
  const [upload, setUpload] = useState(null);
  const [disabledSaveButton, setSisabledSaveButton] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    if (supportedFormats.includes("image")) {
      dispatch(
        clientAction.uploadImage(
          values,
          selectedClient,
          data,
          section,
          sourcePlace,
          forProfileClient
        )
      );
    }
    if (supportedFormats.includes("pdf")) {
      dispatch(
        clientAction.uploadPdfDocument(
          values,
          selectedClient,
          data,
          section,
          documentaryId
        )
      );
    }
    setModelDrawer({ status: false, component: "", title: "" });
  };

  function beforeUpload(file) {
    if (supportedFormats.includes("image")) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" || file.type === "image/webp";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG/JPEG/WEBP file!");
      }
      const isLt5M = file.size / 1024 / 1024 < 100;
      if (!isLt5M) {
        message.error("Image must smaller than 100MB!");
      }
      return isJpgOrPng && isLt5M;
    }

    if (supportedFormats.includes("pdf")) {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        message.error("You can only upload PDF file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        message.error("File must be smaller than 100MB!");
      }
      return isPdf && isLt2M;
    }
  }

  function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  let handleCustome = (event) => {
    // Setting name to show the name of image
    setFileText({ name: event.file.name, size: bytesToSize(event.file.size) });
    setLoading(true);
    setSpinning(true);
    // Preview the image before uploading to our server
    const file = event.file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      setPreviewUrl(base64String);
      setSpinning(false);
    };
  };

  const HandleCancel = () => {
    setModelDrawer({ status: false, component: "", title: "" });
  };

  // image paste event
  useEffect(() => {
    let handlePasteEvent = (event) => {
      setLoading(true);
      setSpinning(true);
      let count = 0;
      var items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;
      for (let index in items) {
        var item = items[index];
        if (item.kind === "file" && count === 0) {
          var blob = item.getAsFile();
          var reader = new FileReader();
          const ide = Math.floor(10000 + Math.random() * 90000);
          const imageName = "pastedImage" + ide;
          var updatedBlob = new File([blob], imageName, { type: blob.type });
          let file = { ...updatedBlob, originFileObj: updatedBlob };
          let uploadData = { upload: { file: file } };
          let checkImage = beforeUpload(updatedBlob);
          if (checkImage) {
            setSisabledSaveButton(false);
            setUpload(uploadData);
            reader.onload = () => {
              const base64String = reader.result
                .replace("data:", "")
                .replace(/^.+,/, "");
              setPreviewUrl(base64String);
              setSpinning(false);
            };
          } else {
            setSisabledSaveButton(true);
          }
          setSpinning(false);
          reader.readAsDataURL(blob);
          count = +count + 1;
        }
      }
    };

    let getModal = document;
    getModal.addEventListener("paste", handlePasteEvent);
    return () => {
      getModal.removeEventListener("paste", handlePasteEvent);
    };
  }, [selectedClient]);

  const HandlePasteImage = () => {
    dispatch(
      clientAction.uploadImage(
        upload,
        selectedClient,
        data,
        section,
        sourcePlace,
        forProfileClient
      )
    );
    setModelDrawer({ status: false, component: "", title: "" });
  };
  return (
    <Form onFinish={onFinish} autoComplete="off" id="formName">
      <div style={{ display: loading ? "none" : "flex" }}>
        <Form.Item
          name="upload"
          // label="Upload File"
          rules={[{ required: true, message: "Please select file!" }]}
        >
          <Dragger
            // accept="application/png"
            // showUploadList={true}
            customRequest={handleCustome}
            beforeUpload={beforeUpload}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single Image upload. Strictly prohibit from
              uploading company data or other band files
            </p>
          </Dragger>
        </Form.Item>
      </div>

      <Form.Item>
        {/* Show spinning when photo is dropped */}
        <div
          style={{
            width: "10%",
            margin: "auto",
            display: spinning ? "flex" : "none",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 64,
            }}
            spin
          />
        </div>

        {/*code for Previewing the image before uploading */}
        <div
          style={{
            display: previewUrl ? "flex" : "none",
            width: "40%",
            margin: "auto",
          }}
        >
          {previewUrl && <Image src={`data:image/png;base64,${previewUrl}`} alt="" />}
        </div>
      </Form.Item>

      {fileText.name && (
        <Form.Item label="File Name ">
          <Text type="secondary">{fileText.name}</Text>
          <br />
          <Text type="secondary">Size: {fileText.size}</Text>
        </Form.Item>
      )}
      <Form.Item
        style={{ marginTop: "-10px" }}
        wrapperCol={{ offset: 10, span: 14 }}
      >
        <Button
          disabled={disabledSaveButton}
          type="primary"
          htmlType="submit"
          style={{ marginRight: "15px", marginLeft: "-20px" }}
          onClick={() => {
            if (upload) {
              HandlePasteImage();
            }
          }}
        >
          Save
        </Button>
        <Button type="primary" onClick={HandleCancel}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
}
export { ImageUploadDetails };
