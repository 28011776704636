import { Modal } from 'antd'
import React from 'react'

function DetailedDocsClauseModal({setModalState, modalState}) {
  return (
    <Modal
        open={true}
        onCancel={() => setModalState({ showDetailModal: false, data: {} })}
        footer={false}
        width={500}
        centered
        style={{ maxHeight: '80vh' }}
      >
        <div style={{ padding: "10px" }}>
          {/* Title Section */}
          {modalState?.data?.title && <>
          <h2 style={{ marginBottom: "10px", fontSize: "24px", color: "#333" }}>
            Title
          </h2>
          <p style={{ fontSize: "18px", marginBottom: "20px", color: "#555", border: "1px solid silver", borderRadius: 5, padding: 5 }}>
            {modalState.data.title}
          </p>
          </>}

          {/* Description Section */}
          <h3 style={{ marginBottom: "10px", fontSize: "20px", color: "#333" }}>
            Description
          </h3>
          <p style={{ fontSize: "16px", color: "#555", border: "1px solid silver", borderRadius: 5, padding: 5, maxHeight: '300px', overflowY: 'auto' }}>
            {modalState?.data?.name}
          </p>
        </div>
      </Modal>
  )
}

export default DetailedDocsClauseModal