import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const getPropertyDataRequest = ({
  type,
  query,
  filterSort,
  source,
  sourceAxios, categoryId, category, readOnly, pageNumber, pageSize,
}) => {
  let accessToken = localStorage.getItem("userAuth");
  let url = `${apiURL}/getCoordinateTransactions`;
  url += `?type=${type}`;
  if(filterSort?.sectionSort?.length){
    url += `&section=${filterSort.sectionSort.join(",")}`;
  }
  if (filterSort?.search?.trim()) {
    url += `&search=${filterSort.search.trim()}`;
  }
  if (filterSort?.statusSort?.length) {
    url += `&statusSort=${filterSort.statusSort.join(",")}`;
  }
  if(filterSort?.dateSort?.type){
    url += `&dateSort=${filterSort.dateSort.type}`;
  }
  if(filterSort?.dateSort?.order){
    url += `&dateSortOrder=${filterSort.dateSort.order}`;
  }
  if(category?.trim()){
    url += `&category=${category}`;
  }
  if(categoryId?.trim()){
    url += `&categoryId=${categoryId}`;
  }
  if(pageNumber){
    url += `&pageNumber=${pageNumber}`;
  }
  if(pageSize){
    url += `&pageSize=${pageSize}`;
  }
  if(readOnly?.value){
    if(readOnly?.delegateOwnerId) url += `&delegateOwnerId=${readOnly?.delegateOwnerId}`;
    if(readOnly?.delegateId) url += `&delegateId=${readOnly?.delegateId}`;
  }

  return axios.get(url, {
    cancelToken: sourceAxios.token,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const getTasksDataRequest = (id, agentType) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/offers/${id}/tasks?agentType=${agentType}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getClientDocRequest = ({params, source}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/propertyDocs`, {
    cancelToken: source.token,
    params,
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getPropertyDocFolderRequest = ({params, source}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/getFolderDetailsInPropertyDocs`, {
    cancelToken: source.token,
    params,
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const createFolderRequest = ({ newFolderData }) => {
  let accessToken = localStorage.getItem("userAuth");

  return axios.post(`${apiURL}/addFolderInPropertyDocs`, newFolderData, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const updateFolderRequest = ({ folderData }) => {
  let accessToken = localStorage.getItem("userAuth");

  return axios.put(`${apiURL}/updateFolderDetailsInPropertyDocs`, folderData, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

const getPropertyDocsFolderFunRequest = ({ params }) => {
  const accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/getFolderDataForPropertyDocs`, {
    params, 
    headers: {
      Accept: "application/json", 
      Authorization: `Bearer ${accessToken}`, 
    },
  });
};

const getMoveToFolderFunRequest = ({ params }) => {
  const accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/getFolderAndSubFoldersDataForPropertyDocs`, {
    params, 
    headers: {
      Accept: "application/json", 
      Authorization: `Bearer ${accessToken}`, 
    },
  });
};

// URL Shortening API

const urlShorthandApi = (data, source, clientToken) => {
  let accessTokan = localStorage.getItem("userAuth");
  if (source === "calendar") {
    if (data?.urlData?.screen === "ReadOnlyClientScreen") {
      accessTokan = clientToken;
    } else if (data?.urlData?.screen === "CLIENT_SCREEN") {
      accessTokan = localStorage.getItem("clientAuth");
    }
    return axios.post(
      `${apiURL}/url`,
      {
        ...data,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      },
    );
  } else {
    return axios.post(
      `${apiURL}/url/builtForSection`,
      {
        ...data,
      },
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      },
    );
  }
};

const getPreNestedDoraDocuments = ({data, source, fiters, brokerageId}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(
    `${apiURL}/preNestedContractDocumentaries?listType=${data.listType}` + (fiters?.search ? `&search=${fiters.search}` : ""),
    {
      cancelToken: source.token,
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    },
  );
};
// upload property docs (not create New upload dora docs)
const uploadPropertyDocsRequest = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/uploadPropertyDocs`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const sharePropertyDocsRequest = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/sharePropertyDocs`, data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getTasksRequest = ({ offerId, agentType, sortBy, orderBy, source, delegateSide, delegateOwnerId }) => {
  const accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/offers/${offerId}/tasks`, {
    cancelToken: source.token,
    params: {
      agentType,
      ...(sortBy?.length > 0 && orderBy?.length > 0 ? { sortBy, order: orderBy } : {}),
      ...(delegateSide ? { delegateSide } : {}),
      ...(delegateOwnerId ? { delegateOwnerId } : {}),
    },
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const addEmailRequest = (data) => {
  const accessTokan = localStorage.getItem("userAuth");
  return axios.post(
    `${apiURL}/addClientDetail`,
    { ...data },
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessTokan}`,
      },
    },
  );
};

const editCustomTaskRequest = ({taskId, urlData,  offerId, delegateSide, delegateOwnerId}) => {
    const accessTokan = localStorage.getItem("userAuth");
    const payload = {
      ...urlData,
      offerId,
      ...(delegateSide ? {delegateSide} : {}),
      ...(delegateOwnerId ? {delegateOwnerId} : {})
    };

    return axios.put(`${apiURL}/tasks/${taskId}`, payload, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessTokan}`,
      },
    });
  };

const completeUncompleteTaskRequest = ({data, source, offerId, delegateSide, delegateOwnerId}) => {
  let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  if(delegateSide) params.append("delegateSide", delegateSide);
  if(delegateOwnerId) params.append("delegateOwnerId", delegateOwnerId);
  if(offerId) params.append("offerId", offerId);
  if (source === "MARK_UNCOMPLETE") {
    return axios.put(
      `${apiURL}/offers/tasks/${data._id}/clearcomplete`,
      params,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      },
    );
  } else {
    if (source === "UPDATE_COMPLETE") params.append("completedDateMili", data.completedDateMili);
    return axios.put(`${apiURL}/offers/tasks/${data._id}/complete`, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }
};

const addCustomTaskRequest = (urlData) => {
  const accessTokan = localStorage.getItem("userAuth");
  return axios.post(
    `${apiURL}/createtask`,
    { ...urlData },
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessTokan}`,
      },
    },
  );
};

const deleteCustomTaskRequest = ({taskId, agentType, offerId, delegateSide, delegateOwnerId}) => {
  const accessTokan = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/deleteManualTask/${taskId}`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
    },
    data: {
      offerId,
      ...(delegateSide ? { delegateSide } : {}),
      ...(delegateOwnerId ? { delegateOwnerId } : {})
    }
  });
};

const postPropertyImageApi =(data)=>{
    const formData = new FormData();
    formData.append('file', data?.file?.originFileObj);
    formData.append('propertyId', data?.propertyId);
    let accessTokan = localStorage.getItem("userAuth");
    return axios.post(`${apiURL}/property/addPropertyImage`, data, {
        headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
        },
  });
}

const deletePropertyImageApi = (imageId) => {
    const accessTokan = localStorage.getItem("userAuth");

    return axios.delete(`${apiURL}/delImage/${imageId}`, {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessTokan}`,
          },
        })
      }

const getNeedToknowRequest = ({offerId, source, delegateSide, delegateOwnerId}) => {
  let accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/needToKnowList/${offerId}`, {
    cancelToken: source.token,
    params: {
      ...(delegateSide ? { delegateSide } : {}),
      ...(delegateOwnerId ? { delegateOwnerId } : {}),
    },
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

const uploadNeedToknowRequest = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(
    `${apiURL}/needToKnowList`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const removeNeedToknowRequest = ({data}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/needToKnowList`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
    data: { ...data },
  });
};

const getMlsConstRequest = ({isMlsId, source}) => {
  const accessTokan = localStorage.getItem("userAuth");
  const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
  };
  const constantType = isMlsId ? "LINK_MLS_CONSTANTS" : "LICENSE_CONSTANTS";
  return axios.get(`${apiURL}/getConstants?constantType=${constantType}&isMlsId=${isMlsId}`, { cancelToken: source.token, headers });
};

const linkMlsApi = (data) => {
  const accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/linkMlsPropertiesToPrivate`,{...data},{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
    },
  })
}

const deLinkMlsApi = (data) => {
  const accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/unlinkMlsPropertyToPrivate`,{...data},{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessTokan}`,
    },
  })
}

const editSellersRequest = (data) => {
    const accessTokan = localStorage.getItem("userAuth");
    return axios.post(`${apiURL}/addClientDetail`,{...data},{
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessTokan}`,
    },
    })
  }

const deleteDraftOfferRequest = ({data}) => {
  const accessTokan = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/propertydocs/deletecontracts`,{
    params: {
      ...data
    },
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${accessTokan}`,
    },
  })
}

const postStatusChange = ({data}) => {
  let accessToken = localStorage.getItem("userAuth");
  const formData = new FormData();

  if(data?.file) formData.append("file", data?.file);
  if(data?.delegateSide) formData.append("delegateSide", data?.delegateSide);
  if(data?.delegateOwnerId) formData.append("delegateOwnerId", data?.delegateOwnerId);
  if (data?.statusChangeFrom) formData.append("statusChangeFrom", data?.statusChangeFrom);
  if (data?.statusChangeTo) formData.append("statusChangeTo", data?.statusChangeTo);
  if (data?.offerId) formData.append("offerId", data?.offerId);
  if (data?.propertyId) formData.append("propertyId", data?.propertyId);
  if (data?.transactionType) formData.append("transactionType", data?.transactionType);
  if (data?.contractType) formData.append("contractType", data?.contractType);

  return axios.post(`${apiURL}/changeTransactionStatus`, formData, {
      headers: {
         "Content-Type": "multipart/form-data",
          accept: "application/json",
          authorization: `bearer ${accessToken}`,
      },
  });
};
// uploadPdfDocument from Doradocuments/property Docs(Dora doc upload) and client docs
const uploadPdfDocument = ({data}) => {
  let accessToken = localStorage.getItem("userAuth");
  const formData = new FormData();

  if(data?.file) formData.append("file", data?.file);
  if(data?.delegateSide) formData.append("delegateSide", data?.delegateSide);
  if(data?.delegateOwnerId) formData.append("delegateOwnerId", data?.delegateOwnerId);
  if (data?.offerId) formData.append("offerId", data?.offerId);
  if (data?.contractId) formData.append("contractId", data?.contractId);
  if (data?.contractType) formData.append("contractType", data?.contractType);
  if (data?.RTDInitiator) formData.append("RTDInitiator", data?.RTDInitiator);
  if (data?.openForPropertyDoc) formData.append("openForPropertyDoc", data?.openForPropertyDoc);
  if (data?.propertyId) formData.append("propertyId", data?.propertyId);
  if (data?.clientDocId) formData.append("clientDocId", data?.clientDocId);
  if (data?.sellerIds?.length) formData.append("sellerIds", JSON.stringify(data?.sellerIds));
  if (data?.buyerIds?.length) formData.append("buyerIds", JSON.stringify(data?.buyerIds));
  if (Array.isArray(data?.buyerAgentIds) && data?.buyerAgentIds.length) {
    formData.append("buyerAgentIds", JSON.stringify(data.buyerAgentIds));
  } 
  if (Array.isArray(data?.sellerAgentIds) && data.sellerAgentIds.length) {
    formData.append("sellerAgentIds", JSON.stringify(data.sellerAgentIds));
  } 

  return axios.post(`${apiURL}/uploadPdfDocument`, formData, {
      headers: {
         "Content-Type": "multipart/form-data",
          accept: "application/json",
          authorization: `bearer ${accessToken}`,
      },
  });
};

const deleteListingRequest = ({docId, delegateOwnerId}) => {
  const accessTokan = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/deleteClientDoc/${docId}`,{
    params: {
      ...(delegateOwnerId ? {delegateOwnerId} : {})
    },
    headers: {
      Accept: "application/json",
      authorization: `Bearer ${accessTokan}`,
    },
  })
}

const deletepropertyDocsRequest = (documentId) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/propertyDocs/${documentId}`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const sendMultipleDocRequest = (data) => {
    const accessTokan = localStorage.getItem("userAuth");
    return axios.post(`${apiURL}/sendMultipleDocs`,{...data},{
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessTokan}`,
    },
    })
  }

 
  
const deletePropertyContractDocsRequest = ({ payloadData }) => {
  let accessToken = localStorage.getItem("userAuth");
  let params = {
    ...(payloadData?.delegateSide && { delegateSide: payloadData.delegateSide }),
    ...(payloadData?.delegateOwnerId && { delegateOwnerId: payloadData.delegateOwnerId }),
    ...(payloadData?.offerId && { offerId: payloadData.offerId }),
    ...(payloadData?.propertyId && { propertyId: payloadData.propertyId }),
    ...(payloadData?.documentId && { documentId: payloadData.documentId }),
    ...(payloadData?.section && { section: payloadData.section })
  };

  return axios.delete(`${apiURL}/propertydocs/deletecontracts`, {
    params,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};


const toggleDocumentInTransactionDashboardRequest = ({ payloadData }) => {
  let accessToken = localStorage.getItem("userAuth");
  let documentId = payloadData?.documentId
  let params = {
    ...(payloadData?.delegateSide && { delegateSide: payloadData.delegateSide }),
    ...(payloadData?.delegateOwnerId && { delegateOwnerId: payloadData.delegateOwnerId }),
    ...(payloadData?.offerId && { offerId: payloadData.offerId }),
    ...(payloadData?.offerId && { offerId: payloadData.offerId }),
    ...(payloadData?.propertyId && { propertyId: payloadData.propertyId }),
    ...(payloadData?.isToggleFrom && { isToggleFrom: payloadData.isToggleFrom }),
    ...(typeof payloadData?.showInTransactionDashboard === 'boolean' && { showInTransactionDashboard: payloadData.showInTransactionDashboard })
  };
  return axios.delete(`${apiURL}/RTD/deleteForm/${documentId}`, {
    params,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
const getAgentDataDetailsRequest = ({ query }) => {
  let accessToken = localStorage.getItem("userAuth");
  const config = {
    method: "GET",
    params: query,
    headers: {
      accept: "application/json",
      authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(`${apiURL}/agentDetails`, config);
};

const editAgentsRequest = (payloadData) => {
  const accessToken = localStorage.getItem("userAuth");
  const config = {
    method: "PUT",
    url: `${apiURL}/editAgentsOnProperty`,
    data: payloadData, 
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios(config);
};

const isContactDeletedOrArchived = (contactId) => {
  const accessToken = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/personDetailedData/${contactId}`,{
    headers:{
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    }
  })
}

const getOwnersDataApi = (ownerId) => {
  const accessToken = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/owners`,
  {selectedOwnerId: ownerId},
  {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const deletePropertyDocFolder = (folderId) => {
  const accessToken = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/deletePropertyDocsFolder/${folderId}`,{
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const moveToFolderFunRequest = ({params}) => {
  let accessToken = localStorage.getItem("userAuth");

  return axios.put(`${apiURL}/updateFolderDetailsInPropertyDocs`, params, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
}


export const propertyDataApis ={
  getPropertyDataRequest,
  getTasksDataRequest,
  getClientDocRequest,
  urlShorthandApi,
  getPreNestedDoraDocuments,
  uploadPropertyDocsRequest,
  sharePropertyDocsRequest,
  getTasksRequest,
  addEmailRequest,
  getNeedToknowRequest,
  uploadNeedToknowRequest,
  removeNeedToknowRequest,
  getMlsConstRequest,
  linkMlsApi,
  deLinkMlsApi,
  editCustomTaskRequest,
  completeUncompleteTaskRequest,
  addCustomTaskRequest,
  deleteCustomTaskRequest,
  postPropertyImageApi,
  deletePropertyImageApi,
  editSellersRequest,
  deleteDraftOfferRequest,
  deleteListingRequest,
  deletepropertyDocsRequest,
  sendMultipleDocRequest,
  postStatusChange,
  uploadPdfDocument,
  deletePropertyContractDocsRequest,
  toggleDocumentInTransactionDashboardRequest,
  getAgentDataDetailsRequest,
  editAgentsRequest,
  isContactDeletedOrArchived,
  getOwnersDataApi,
  createFolderRequest,
  getPropertyDocFolderRequest,
  updateFolderRequest,
  getPropertyDocsFolderFunRequest,
  deletePropertyDocFolder,
  getMoveToFolderFunRequest,
  moveToFolderFunRequest,
}
