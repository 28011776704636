import React, { useState } from 'react'
import { Button, Checkbox, Tooltip } from "antd";
import { FileImageOutlined, FileTextOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { FaRegFilePdf } from "react-icons/fa";
import { conterOfferContractTypes, createOfferContractTypes } from '../../Common/commondata/commonData';
import { canPropertyDocItemBeDeleted } from '../utils/helper';


const PropertyDocFile = ({ele,  role, item, handleOPenDocs, handlePropertyDocs, handleShareUnshareFileConfirm, handleToggleDashboard, handleDeletePropertyDocs, isSmallScreen,  handleDragStart, selectedFiles, setSelectedFiles}) => {
    const [hoveredRow, setHoveredRow] = useState(null);

    const handleSelectFile = (fileId, contractId) => {
        if(contractId){
            let updatedOfferDocs = selectedFiles?.offerDocs?.filter((el) => el?.offerDocId === fileId)?.length ? selectedFiles?.offerDocs?.filter((el) => el?.offerDocId !== fileId) : [...selectedFiles?.offerDocs,{offerDocId: fileId, selectedParentId:null}]
            setSelectedFiles({...selectedFiles,offerDocs:updatedOfferDocs})
        }else{
            let updatedPropDocs = selectedFiles?.propertyDocs?.filter((el) => el?.propertyDocId === fileId)?.length ? selectedFiles?.propertyDocs?.filter((el) => el?.propertyDocId !== fileId) : [...selectedFiles?.propertyDocs,{propertyDocId: fileId, selectedParentId:null}]
            setSelectedFiles({...selectedFiles,propertyDocs:updatedPropDocs})
        }
    };

    return (
        <>
            <tr
                key={ele?._id}
                draggable
                onDragStart={(e) => handleDragStart(e, ele)}
            >
                <td
                    onClick={() => {
                        ele.isPdf === true
                            ? window.open(ele.pdfLink, "_blank")
                            : ele.fileType === "IMAGE" || ele.fileType === "PDF"
                                ? handleOPenDocs(ele)
                                : handlePropertyDocs(ele);
                    }}
                    style={{
                        fontWeight: "bolder",
                        color: "blue",
                        minWidth: "250px",
                        width: "65%",
                        cursor: "pointer",
                        border: "none"
                        
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: isSmallScreen ? "13px" : "16px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <div style={{width: "26px", height: "26px"}}   onMouseEnter={() => setHoveredRow(ele?._id)} onMouseLeave={() => setHoveredRow(null)} >
                                {(hoveredRow === ele?._id || selectedFiles?.propertyDocs?.filter((el) => el?.propertyDocId === ele?._id)?.length || selectedFiles?.offerDocs?.filter((el) => el?.offerDocId === ele?._id)?.length) ? (
                                        <Checkbox
                                            checked={ele?.contractId ? selectedFiles?.offerDocs?.filter((el) => el?.offerDocId === ele?._id)?.length : selectedFiles?.propertyDocs?.filter((el) => el?.propertyDocId === ele?._id)?.length}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleSelectFile(ele?._id,ele?.contractId ? true : false);
                                            }}
                                            style={{marginRight:"4px"}}
                                        />
                                ) : <>
                                {ele.fileType === "IMAGE" ? (
                                    <FileImageOutlined
                                        style={{
                                            color: "#ecd540",
                                            marginTop: "5px",
                                            marginRight: "5px",
                                            fontSize: "18px"
                                        }}
                                    />
                                ) : ele.fileType === "PDF" || ele.isPdf ? (
                                    <FaRegFilePdf
                                        style={{
                                            color: "red",
                                            marginTop: "5px",
                                            marginRight: "5px",
                                            fontSize: "18px"
                                        }}
                                    />
                                ) : ele.offerDocument && ele.contractType === "LCAES" ? (
                                    <FileTextOutlined
                                        style={{
                                            color: "#74b3ed",
                                            marginRight: "5px",
                                            fontSize: "18px"
                                        }}
                                    />
                                ) : (
                                    <FileTextOutlined
                                        style={{
                                            color: "#74b3ed",
                                            marginRight: "5px",
                                            fontSize: "18px"
                                        }}
                                    />
                                )}
                                </>}
                            </div>
                            <span>
                                {ele.offerDocument ? ele.documentName : ele.fileName || ""}
                                {ele.offerDocument && !ele?.offerId && !["ERTS", "LCAES", "FRASCONA_ERTS"].includes(ele.contractType) && (
                                    <span style={{ color: "grey", fontSize: "12px" }}>
                                        {" (Without Buyers)"}
                                    </span>
                                )}
                            </span>
                        </div>
                        {ele?.transactionData && (
                            <div
                                style={{
                                    color: "grey",
                                    fontSize: "11px",
                                    marginLeft: "24px",
                                }}
                            >
                                {["ERTS", "FRASCONA_ERTS"].includes(ele.contractType) &&
                                    ele.transactionData &&
                                    ele.transactionData.ListingPeriodBeginDate && (
                                        <span>
                                            {moment(ele.transactionData.ListingPeriodBeginDate).format(
                                                "MM/DD/YYYY"
                                            )}
                                        </span>
                                    )}
                                {["ERTS", "FRASCONA_ERTS"].includes(ele.contractType) &&
                                    ele.transactionData &&
                                    ele.transactionData.ListingPeriodEndByDate && (
                                        <>
                                            {" "}
                                            -{" "}
                                            {moment(
                                                ele.transactionData.ListingPeriodEndByDate
                                            ).format("MM/DD/YYYY")}
                                        </>
                                    )}
                                <span style={{ marginLeft: "19px" }}>
                                    {ele.contractType === "LCAES" &&
                                        ele.transactionData &&
                                        ele.transactionData.ListingPeriodEndByDate && (
                                            <span>Ending Date </span>
                                        )}
                                    {ele.contractType === "LCAES" &&
                                        ele.transactionData &&
                                        ele.transactionData.ListingPeriodEndByDate && (
                                            <span>
                                                {" "}
                                                -{" "}
                                                {moment(ele.transactionData.ListingPeriodEndByDate).format(
                                                    "MM/DD/YYYY"
                                                )}
                                            </span>
                                        )}
                                </span>
                            </div>
                        )}
                        <div
                            style={{
                                color: "grey",
                                fontSize: "11px",
                                marginLeft: "20px",
                            }}
                        >
                            {ele.fileType
                                ? `Uploaded by: ${ele.personId?.fullName}`
                                : ""}
                        </div>
                    </div>
                </td>

                <td
                    style={{
                        color: "black",
                        fontWeight: "500",
                        textAlign: "center",
                        minWidth: "50px",
                        width: "10%",
                        whiteSpace: "pre-wrap",
                        border: "none"
                    }}
                >
                    <div>
                        {moment(ele.createdDateMili).format("MM/DD/YYYY")}
                    </div>
                    <div>
                        {moment(ele.createdDateMili).format("hh:mm A")}
                    </div>
                </td>

                <td
                    style={{
                        fontWeight: "bolder",
                        textAlign: "center",
                        minWidth: "70px",
                        width: "10%",
                        color: "#085191",
                        border: "none"
                    }}
                >
                    {!ele?.contractId && (
                        role === "SELLER" && item?.offerId ? (
                            <Checkbox
                                checked={ele?.isShared}
                                onChange={(e) => {
                                    handleShareUnshareFileConfirm(ele, e.target.checked);
                                }}
                                style={{
                                    color: ele.isShared ? "white" : "black",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                            />
                        ) : role === "BUYER" && ele?.showSeller ? (
                            <Checkbox
                                checked={ele?.isShared}
                                onChange={(e) => {
                                    handleShareUnshareFileConfirm(ele, e.target.checked);
                                }}
                                style={{
                                    color: ele?.isShared ? "white" : "black",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                            />
                        ) : (
                            <Tooltip
                                title={
                                    role === "SELLER" && !item?.offerId
                                        ? "Cannot share: Accepted offer does not exist."
                                        : role === "BUYER" && !ele?.showSeller
                                            ? "Please send this offer to seller agent to enable sharing."
                                            : ""
                                }
                                placement="right"
                                style={{ width: "50px" }}
                            >
                                <Checkbox
                                    checked={false}
                                    disabled={true}
                                    style={{
                                        color: "black",
                                        borderRadius: "5px",
                                        cursor: "not-allowed",
                                    }}
                                />
                            </Tooltip>
                        )
                    )}
                </td>

                <td
                    style={{
                        textAlign: "center",
                        minWidth: "100px",
                        width: "15%",
                        border: "none"
                    }}
                >
                    {ele?.offerId &&
                        ele?.offerDocument &&
                        ele?.contractId &&
                        !["ERTS", "NET_SHEET", "FRASCONA_ERTS"].includes(ele?.contractType) && (
                            <Checkbox
                                checked={ele?.isCreatedInRtd}
                                onChange={(e) =>
                                    handleToggleDashboard(ele, e.target.checked)
                                }
                                disabled={
                                    ((createOfferContractTypes?.includes(ele?.contractType) &&
                                        item?.status === "ACCEPTED") || (conterOfferContractTypes?.includes(ele?.contractType) &&
                                            item?.propertyStatus === "ACCEPTED"))
                                }
                                style={{
                                    color: "black",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                            />
                        )}
                </td>

                <td
                    style={{
                        textAlign: "center",
                        minWidth: "70px",
                        width: "10%",
                        color: "#085191",
                        border: "none"
                    }}
                >
                    {canPropertyDocItemBeDeleted({ eachDoc:ele, getCoordinateItem: item }) &&
                        (
                            <Tooltip title="Delete" placement="right" style={{ width: "50px" }}>
                                <Button
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                        handleDeletePropertyDocs(
                                            ele,
                                            ele?.RTDInitiator
                                                ? "contractDoc"
                                                : "uploadedDoc"
                                        )
                                    }
                                    style={{
                                        backgroundColor: "transparent",
                                        color: "red",
                                        border: "none",
                                    }}
                                />
                            </Tooltip>
                        )}
                </td>
            </tr>

            <tr style={{ border: "1px solid #d3d3d3", height: 0 }}>
                <td colSpan={5} style={{ border: "1px solid #d3d3d3", margin: 0, padding: 0 }}></td>
            </tr>
        </>
    )
}

export default PropertyDocFile
