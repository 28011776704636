import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

// getClauses Data
const getClausesRequest = ({ search, type, sourceAxios, pageSize = 50, pageNumber = 1 }) => {
  let accessToken = localStorage.getItem("userAuth");
  let url = `${apiURL}/docAndClause/CLAUSE?pageSize=${pageSize}&pageNumber=${pageNumber}`;

  if (search?.length) {
    url += `&search=${search?.trim()}`;
  }
  if (type?.length) {
    url += `&filterByType=${type}`;
  }

  return axios.get(url, {
    cancelToken: sourceAxios?.token,
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessToken}`,
    },
  });
};

  const getDocumentsRequest = ({search, type, sourceAxios, pageSize = 50, pageNumber = 1 }) => {
    let accessToken = localStorage.getItem("userAuth");
    let url = `${apiURL}/docAndClause/DOCUMENT?pageSize=${pageSize}&pageNumber=${pageNumber}`;

    if (search?.length) {
      url += `&search=${search?.trim()}`;
    }
    if (type?.length) {
      url += `&filterByType=${type}`;
    }

    return axios.get(url, {
      cancelToken: sourceAxios?.token,
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessToken}`,
      },
    });
};
const getemailtemplateRequest = ({source}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/docAndClause/EMAIL_TEMPLATE`, {
    cancelToken: source.token,
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};
// adding docs and clauses
const addDocAndClauseRequest = (payload) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/docAndClause`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};
// update any particular docs or clause name
const updateDocsClauseRequest = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  if(data.creationType){
    return axios.put(
        `${apiURL}/docAndClause/${data._id}`,
        {name: data.name, title: data.title, creationType: data.creationType},
        {
          headers: {
            accept: "application/json",
            authorization: `bearer ${accessTokan}`,
          },
        }
      );
  }else{
  return axios.put(
    `${apiURL}/docAndClause/${data._id}`,
    { name: data.name, title: data.title },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
}
};

// delete any particular docs and clause name
const deleteDocsClauseRequest = (id) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/docAndClause/${id}`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const postEmailBroadCaste = (subject,message,email) => {
  let payload={
    subject:subject,
    message:message,
    emails:email
}
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/emaildripbroadcaste`, payload, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
}


const emailDripCampaign = () => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/emaildrip`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};
const emailDripCampaignrealtor = () => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/emaildrip/realtor`, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};
const updateEmailDripCampaign = (id,data) => {
let updateData={
  isChecked:data.isChecked,
  eventMessage:data.message
}

  let accessTokan = localStorage.getItem("userAuth");
  return axios.put(`${apiURL}/emaildrip/${id}`,updateData, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const postEmailDripEvent=(data)=>{
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(`${apiURL}/emaildrip`,data, {
    headers: {
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
}

const sendMailForClausesRequest = (source,personDetailData) => {
    let payload={
      source,
      personDetailData
  }
    let accessTokan = localStorage.getItem("userAuth");
    return axios.post(`${apiURL}/sendRequestForDocsAndClauses`, payload, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }

const getFJGGAccessRequest = () => {
    let accessTokan = localStorage.getItem("userAuth");
    return axios.get(`${apiURL}/FJGGAccess`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  };

  const getResureSignSSOLink = () => {
    let accessTokan = localStorage.getItem("userAuth");
    return axios.get(`${apiURL}/person/sso/resure-sign`, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  };

export const toolsApi = {
  getClausesRequest,
  postEmailDripEvent,
  getDocumentsRequest,
  addDocAndClauseRequest,
  updateDocsClauseRequest,
  deleteDocsClauseRequest,
  getemailtemplateRequest,
  postEmailBroadCaste,
  updateEmailDripCampaign,
  emailDripCampaignrealtor ,
  emailDripCampaign,
  sendMailForClausesRequest,
  getFJGGAccessRequest,
  getResureSignSSOLink,
};
