/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { SideBar } from "./SideBar";
import { Navbar } from "./Navbar";
import { Layout, Alert, Result, Button } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { accountHandle } from "../../Account/state/actions";
import { appStatusChange } from "../state/actions";
import dotEnv from "dotenv";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pricing from "../../Pricing/Pricing";
import { userSignUpAction } from "../../User/UserAuthenticate/state/actions";
import { decryptQuery, getValuesFromUrl } from "../../Common/utils/extraFunctions";
import { buildRedirectPath, handleValidSession } from "../utils/helper";

const { Content } = Layout;
dotEnv.config();
const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;
/**
 * @function PrivateRoute
 * @param {Object} Object parent object paraments
 * @returns {Object} JSX.Element
 * Higher order function that validates the auth token before proceeding
 */

export const PrivateRoute = ({ component: Component, roleDisplay, networkConnection, ...rest }) => {
  const dispatch = useDispatch();
  let accessToken = localStorage.getItem("userAuth");
  const { wholeAppLoading, wholeAppError, getUserDetailsLoading, subscriptionRequired, subscriptionExists, getUserDetailsError, userId } = useSelector((state) => state.account, shallowEqual);
  const { manualLoading } = useSelector((state) => state.app);

  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const urlId = getValuesFromUrl(urlParams.get("urlId"));
  const sessionId = getValuesFromUrl(urlParams.get("sessionId"));
  const offerId = getValuesFromUrl(urlParams.get("offerId"));
  const dest = urlParams.get("dest");
  const email = urlParams.get("email");
  const role = urlParams.get("role");
  const search = urlParams.get("search");
  const auth = decryptQuery(urlParams.get("auth"));
  const delegateOwnerId = urlParams.get("delegateOwnerId");

  let redirectpathname = "",
    searchQuery = "";
  if (dest) {
    redirectpathname = buildRedirectPath({ dest, role, urlId, offerId, adminURL, delegateOwnerId });
    searchQuery = (() => {
      const queryParams = new URLSearchParams(window.location.search || "");
      ["sessionId", "auth", "email"].forEach((key) => queryParams.delete(key));
      return queryParams?.toString() ? `?${queryParams.toString()}` : ""; 
    })();    
  }

  useEffect(() => {
    if (auth && dest) {
      dispatch(appStatusChange.setmanualLoadingFun(true));
      if (accessToken) {
        // Logingout existing user to handle new auth
        dispatch(userSignUpAction.logOut(history, "AUTOMATIC"))?.then(() => {
          localStorage.setItem("userAuth", auth);
          handleValidSession({ dest, redirectpathname, history, searchQuery: search === "false" ? "" : searchQuery });
        });
      } else {
        localStorage.setItem("userAuth", auth);
        dispatch(accountHandle.getUserDetails(auth));
        handleValidSession({ dest, redirectpathname, history, searchQuery: search === "false" ? "" : searchQuery });
      }
    } else if (accessToken) {
      if (dest) dispatch(appStatusChange.setmanualLoadingFun(true));
      dispatch(accountHandle.getUserDetails());
    }
  }, []);

  // console.log("userId && dest && sessionId", {userId} , {dest} , {role}, {sessionId}, "redirectpathname", redirectpathname, "searchQuery", searchQuery);

  useEffect(() => {
    if (userId && dest && sessionId && accessToken && !auth) {
      if (sessionId === userId) {
        dispatch(appStatusChange.setmanualLoadingFun(false));
        handleValidSession({ dest, redirectpathname, history, searchQuery });
      } else {
        dispatch(userSignUpAction.logOut(history, "AUTOMATIC"))?.then(() => {
          history.push({
            pathname: "/login",
            state: { redirectpathname: redirectpathname, dest, sessionId, email, searchQuery },
          });
        });
      }
    }
    if (!sessionId && accessToken && manualLoading) {
      dispatch(appStatusChange.setmanualLoadingFun(false));
    }
    return () => {};
  }, [userId, accessToken, dest, role]);

  const handleLogin = () => {
    localStorage.removeItem("userAuth");
    window.location.href = "/login";
  };

  return wholeAppLoading ? (
    <div className="loading-container">
      <Spin size="large" />
    </div>
  ) : wholeAppError ? (
    "...error"
  ) : (
    <>
      <Route
        {...rest}
        render={(props) =>
          accessToken ? (
            <>
              {getUserDetailsLoading || manualLoading ? (
                <div className="loading-container">
                  <Spin size="large" />
                </div>
              ) : getUserDetailsError ? (
                <Result
                  status="404"
                  title="404"
                  subTitle="Not Authorized"
                  extra={
                    <Button type="primary" onClick={handleLogin}>
                      Login
                    </Button>
                  }
                />
              ) : subscriptionRequired && !subscriptionExists ? (
                <div>
                  <Navbar />
                  <Pricing />
                </div>
              ) : (
                <div
                  style={{
                    height: "100vh",
                    overflow: "hidden",
                  }}
                >
                  <Navbar />
                  {location.pathname === "/license" ? "" : <SideBar />}
                  <Layout id="mainLayout">
                    <Content
                      style={{
                        backgroundColor: "rgb(255 255 255)",
                        padding: "0rem 0rem 0rem 0rem",
                      }}
                    >
                      {networkConnection && (
                        <div>
                          <Alert message="No internet" type="error" />
                        </div>
                      )}
                      <Component networkConnection={networkConnection} {...props} />
                    </Content>
                  </Layout>
                </div>
              )}{" "}
            </>
          ) : (
            <>
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    ...(dest
                      ? {
                          redirectpathname,
                          dest,
                          sessionId,
                          email,
                          searchQuery,
                        }
                      : {}),
                  },
                }}
              />{" "}
            </>
          )
        }
      />
    </>
  );
};
