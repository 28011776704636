import React from 'react'
import { useSelector } from 'react-redux';
import { Loading } from '../../Common';
import { List, message, Tooltip } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

export default function ThirdStepMLSModal() {
    const { linkingMlsData, linkingMlsError, linkingMlsLoading, linkingMlsErrorMsg } = useSelector((state) => state.PropertyReducer);
    let Mlslogos = [
        {
        type: "RECOLORADO",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage82704",
        },
        {
        type: "IRES",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage84057",
        },
        {
        type: "PPAR",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/657ff2157e660a0701cfaa24/MEMORIES/19PPMLS-logo-MAIN-WebRGB.jpeg",
        },
        {
        type: "PAR",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/qa/6444b9c521382a6780572f63/MEMORIES/2pastedImage68271",
        },
        {
        type: "GJARA",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/prod/64cb4128b00a232d9c2cc0d4/MEMORIES/29gjara-logo.png",
        },
    ];

    const getImageSrc = (propertyType) => {
        const image = Mlslogos.find((img) => propertyType.includes(img.type));
        return image ? image.src : "";
    };
    return (
        <>
            {linkingMlsLoading ? (
            <div
                style={{ display: "flex", justifyContent: "center", height: "50vh" }}
            >
                <Loading />
            </div>
            ) : linkingMlsError ? "" : (
            <div
                style={{
                display: "flex",
                flexDirection: "column",
                height: "60vh",
                overflow: "hidden",
                overflowY: "auto",
                }}
            >
                <List
                itemLayout="horizontal"
                dataSource={linkingMlsData}
                renderItem={(item) => (
                    <List.Item>
                    <List.Item.Meta
                        avatar={
                        item.type === "SUCCESS" ? (
                            <CheckCircleFilled
                            style={{
                                fontSize: "23px",
                                color: "green",
                                marginTop: "5px",
                            }}
                            />
                        ) : (
                            <CloseCircleFilled
                            style={{
                                fontSize: "23px",
                                color: "tomato",
                                marginTop: "5px",
                            }}
                            />
                        )
                        }
                        title={
                        <div style={{display:"flex",lineHeight:"17px"}}>
                            <div style={{width: "80%"}}>
                                <span style={{fontSize: "17px",color:"rgba(0, 0, 0, 0.7)"}}>{item.fullAddress}</span>
                                <div style={{ marginBottom: "0px",fontWeight:"bold",fontSize:"13px" }}>
                                    {item.message}
                                </div>
                            </div>
                            <div style={{display:"flex",justifyContent:"center", width: "20%",alignItems:"center",}}>
                                <Tooltip title={item?.propertyType?.includes("RECOLORADO") ? "REcolorado" : item?.propertyType} trigger={'hover'}>
                                    <img
                                        src={getImageSrc(item.propertyType)}
                                        alt="logo"
                                        style={{
                                            //   marginLeft: "10px",
                                            height: "30px",
                                            width: "80px",
                                            objectFit: "contain",
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        }
                        style={{
                            boxShadow:
                                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                            padding: "5px",
                            borderRadius: "5px",
                            border: "1px solid grey",
                        }}
                    />
                    </List.Item>
                )}
                />
            </div>
            )}
        </>
    )
}
