import { Modal } from 'antd'
import React from 'react'
import CreateDocs from '../../PropertyComp/components/CreateDocs'

function BrokerageFormsModal({brokerageId, setModalStates, modalStates}) {
  return (
    <Modal
        open={true}
        title={<div style={{textAlign: 'center', color: '#1890ff'}}>BROKERAGE FORMS</div>}
        footer={false}
        onCancel={()=> setModalStates((prev) => ({...prev, brokerageFormsModal: false}))}
    >
        <CreateDocs role={'BROKERAGE_DOC'} />
    </Modal>
  )
}

export default BrokerageFormsModal