import { Button, Input, Modal, Typography, Popconfirm, Form, Select, Checkbox, Col, Image, Row } from "antd";
import React from "react";
import { bugReportHandle } from "../state/actions";
import { PlusCircleFilled, InboxOutlined, CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import ReactQuill from "react-quill";
import { roleAccessFunc } from "../../Common/utils/extraFunctions";
import { useEffect } from "react";

const { Text } = Typography

const EditBugModal = ({ handleCancel, formItemLayout, onFinish, handleQuillPaste, priorityOptions, typeOptions, handleAddFile, handleChangeImage, deleteImg, handleCustome, beforeUpload, renderComp, modalVisible, spot6, isMobile, form, handleData, quillRef, spot8, spot7, roleAccessed, role, input, dispatch, userDataPage, setInput, setSearchUserData, setUserModalVisible, setTypeChanged, usersData, UpdateImagedata, isTab1, bugPostLoading }) => {

    useEffect(() => {
        form.setFieldsValue({
            title: handleData?.[0]?.title,
            platform: handleData?.[0]?.platform,
            priority: handleData?.[0]?.priority,
            type: handleData?.[0]?.type,
        })
    }, [handleData])

    return (
        <Modal
            title={<span style={{ display: "flex", justifyContent: "center", fontSize: "17px", color: "grey", position: "sticky", top: 0, background: "#fff", zIndex: 1 }}>UPDATE {renderComp === "listings" ? "BUG" : renderComp === "suggestions" ? "SUGGESTION" : "TICKET"} DETAILS</span>}
            visible={modalVisible}
            width={spot6 ? "80%" : isMobile ? "" : "60%"}
            onCancel={handleCancel}
            footer={false}
            style={{ top: "5%" }}
        >
            <div style={{ overflowY: "auto", maxHeight: "600px", padding: "0 10px" }}>
                <Form
                    {...formItemLayout}
                    onFinish={onFinish}
                    form={form}
                    name="register"
                    scrollToFirstError
                >
                    <Form.Item
                        name="title"
                        label="Title"
                        initialValue={handleData?.[0]?.title}
                    >
                        <Input name="title" disabled />
                    </Form.Item>

                    <Form.Item
                        name="content"
                        label="Add To Description"
                        onPaste={handleQuillPaste}
                        rules={[
                            {
                                required: false,
                                message: "Please input Bug Description",
                            },
                        ]}
                    >
                        <ReactQuill
                            ref={quillRef}
                            style={{ height: "200px", marginBottom: spot8 ? "82px" : spot7 ? "100px" : "120px", maxWidth: "100%" }}
                            placeholder="Description"
                            modules={{
                                toolbar: [
                                    ["bold", "italic", "underline", "strike"], // toggled buttons
                                    ["blockquote", "code-block"],
                                    [{ header: 1 }, { header: 2 }], // custom button values
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    [{ script: "sub" }, { script: "super" }], // superscript/subscript
                                    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                                    [{ direction: "rtl" }], // text direction
                                    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                                    [{ font: [] }],
                                    [{ align: [] }],
                                    ["clean"],// remove formatting button
                                ],
                            }}
                        />
                    </Form.Item>

                    <Form.Item name="platform" label="Platform" initialValue={handleData?.[0]?.platform}
                        rules={[
                            {
                                required: true,
                                message: "Please select a platform!",
                            },
                        ]}
                    >
                        <Checkbox.Group>
                            <Checkbox value="MOBILE">Mobile</Checkbox>
                            <Checkbox value="WEB">Web</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>

                    {(roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS") || role) ? <Form.Item
                        name="assignedTo"
                        label="Re-Assign To"
                    >
                        <Row>
                            <Col span={20} style={{ marginRight: '15px' }}>
                                <Input value={input} placeholder="Click on search button for list of users" disabled></Input>
                            </Col>
                            <Col span={2} style={{ alignSelf: "center", textAlign: "end" }}>
                                <Button
                                    onClick={() => {
                                        if (usersData?.length === 0 || !input) {
                                            dispatch(bugReportHandle.getUsersData(userDataPage));
                                        }
                                        setInput("")
                                        setSearchUserData("")
                                        setUserModalVisible(true)
                                    }}
                                    style={{ padding: "0px 6px" }}
                                >
                                    <SearchOutlined style={{ fontSize: "18px" }} />
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item> : null}

                    <Form.Item name="priority" label="Priority" initialValue={handleData?.[0]?.priority}>
                        <Select
                            placeholder="Select Priority"
                            showArrow
                            mode="single"
                            options={priorityOptions}
                            size="medium"
                        />
                    </Form.Item>

                    <Form.Item name="type" label="Move Ticket To" initialValue={handleData?.[0]?.type}>
                        <Select
                            placeholder="Select type"
                            showArrow
                            mode="single"
                            options={typeOptions}
                            size="medium"
                            onChange={() => setTypeChanged(true)}
                        />
                    </Form.Item>

                    <Form.Item label={isMobile ? <div><span style={{ color: "#1990ff", fontSize: "27px", cursor: "pointer" }} onClick={handleAddFile}><PlusCircleFilled /></span> <span style={{ marginLeft: "5px" }}>Images or Ctrl-V</span></div> : <div style={{ marginTop: "30px" }}><div><span style={{ color: "#1990ff", fontSize: "27px", cursor: "pointer" }} onClick={handleAddFile}><PlusCircleFilled /></span> <span style={{ marginLeft: "5px" }}>Images</span></div><div style={{ fontSize: "16px", marginLeft: "20px" }}>or Ctrl-V</div></div>}>
                        {/*  */}
                        {handleData?.[0]?.imageUrls?.length > 0 || UpdateImagedata && UpdateImagedata?.length > 0 ? (
                            <Form.Item>
                                <div style={{ display: "flex", maxWidth: isTab1 ? "100%" : "40vw", minHeight: "200px", overflow: "auto", boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px" }}>
                                    {handleData && handleData[0]?.imageUrls?.map((file, index) => (
                                        <div key={index} style={{ margin: "10px" }} >
                                            <Image width={100} src={file} preview={{
                                                visible: false,
                                                mask: (
                                                    <div
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            zIndex: 21,
                                                            top: "0px",
                                                            right: "0px",
                                                        }}
                                                    >
                                                        <div
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                            }}
                                                        >
                                                            <Popconfirm
                                                                title="Are you sure you want to delete this image?"
                                                                onConfirm={() => {
                                                                    handleChangeImage(file, index);
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <CloseCircleOutlined style={{ fontSize: "30px" }} />
                                                            </Popconfirm>
                                                        </div>
                                                    </div>
                                                ),
                                            }} />
                                        </div>
                                    ))}



                                    {UpdateImagedata && UpdateImagedata?.length > 0 ? (
                                        <Form.Item>
                                            <div style={{ display: "flex", }}>
                                                {UpdateImagedata?.map((file, index) => (

                                                    <div key={index} style={{ margin: "10px" }} >
                                                        <Image width={100} src={file.file} preview={{
                                                            visible: false,
                                                            mask: (
                                                                <div
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        position: "absolute",
                                                                        zIndex: 21,
                                                                        top: "0px",
                                                                        right: "0px",
                                                                    }}
                                                                >
                                                                    <div
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                        }}
                                                                    >
                                                                        <Popconfirm
                                                                            title="Are you sure you want to delete this image?"
                                                                            onConfirm={() => {
                                                                                deleteImg(index)
                                                                            }}
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                        >
                                                                            <CloseCircleOutlined style={{ fontSize: "30px" }} />
                                                                        </Popconfirm>
                                                                    </div>
                                                                </div>
                                                            ),
                                                        }} />
                                                        <br />
                                                        <Text type="secondary">{file.name}</Text>
                                                        <br />
                                                        <Text type="secondary">Size: {file.size}</Text>
                                                    </div>
                                                ))}
                                            </div>

                                        </Form.Item>
                                    ) : null}
                                </div>
                            </Form.Item>


                        ) : (
                            <Dragger
                                accept="image/*"
                                showUploadList={false}
                                customRequest={handleCustome}
                                beforeUpload={beforeUpload}
                                multiple
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    Click or drag or paste file to this area to upload
                                </p>
                                <p className="ant-upload-hint">
                                    Support for multiple image uploads. Strictly prohibit from
                                    uploading company data or other banned files
                                </p>
                            </Dragger>
                        )}
                    </Form.Item>
                    <Form.Item>

                        <div style={{ display: "flex", justifyContent: "center", }}>
                            <Button
                                type="primary"
                                style={{
                                    width: isMobile ? "100%" : "55%",
                                    textAlign: "center"
                                }}
                                htmlType="submit"
                                loading={bugPostLoading}
                            >
                                Save
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}

export default EditBugModal