import { Button, Form, Modal, Steps } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../Common/components/Loading";
import { documentAction } from "../../DocumentSection/state/actions";
import FirstStepMLSModal from "./FirstStepMLSModal";
import SecondStepMLSModal from "./SecondStepMLSModal";
import { PropertyHandlers } from "../state/actions";
import ThirdStepMLSModal from "./ThirdStepMLSModal";

const MLSModal = ({ isMLSVisible, handleMLSCancel, selectedData, filterSort, handleLinkMoreMls, section }) => {
    const [form] = Form.useForm();
    const delegate = (section === "DELEGATE");
    let { getPropertySearchData, getPropertySearchLoading } = useSelector((state) => state.documentSection.PropertySearchReducer,);
    const { linkingMlsLoading, deLinkingMlsLoading } = useSelector((state) => state.PropertyReducer);
    const [data, setData] = useState({
        selectedMlsType: [],
        selectedProperty: []
    });
    const [currentStep, setCurrentStep] = useState(0);

    const delegateSide = delegate && "SELLER";
    const delegateOwnerId = delegate && selectedData?.currentAgentId?._id;
    const { Step } = Steps;
    const dispatch = useDispatch();

    let Mlslogos = [
        {
        type: "RECOLORADO",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage82704",
        },
        {
        type: "IRES",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage84057",
        },
        {
        type: "PPAR",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/657ff2157e660a0701cfaa24/MEMORIES/19PPMLS-logo-MAIN-WebRGB.jpeg",
        },
        {
        type: "PAR",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/qa/6444b9c521382a6780572f63/MEMORIES/2pastedImage68271",
        },
        {
        type: "GJARA",
        src: "https://resureprojectbucket.s3.us-east-2.amazonaws.com/prod/64cb4128b00a232d9c2cc0d4/MEMORIES/29gjara-logo.png",
        },
    ];

    const handleFinish =()=>{
        dispatch(documentAction.resetSearchProperties());
        triggerFunc();
    }

    const triggerFunc = () => {
        dispatch(PropertyHandlers.changeFilterSort({...filterSort}));
    }

    const steps = [
        {
            title: "Select MLS",
            content: <FirstStepMLSModal data={data} setData={setData} section={section} selectedData={selectedData}/>,
        },
        {
            title: "Search Properties",
            content: <SecondStepMLSModal form={form} data={data} setData={setData} isMLSVisible={isMLSVisible}/>,
        },
        {
            title: "Confirm",
            content: <ThirdStepMLSModal />,
        },
    ];

    const nextStep = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleLinkMls = () => {
        Modal.confirm({
            title: 'Confirm',
            content: `${checkPropertyAddress(data.selectedProperty) ? `The address on this property does not match the listing${data.selectedProperty.length > 1 ? "'" + "s" : ""} you selected and will update the address in RE-Sure to match the MLS address.` : ""} Are you sure you want to link to the selected listing${
                data.selectedProperty.length > 1 ? "'" + "s" : ""
            }?`,
            okText: "Yes",
            cancelText: "No",
            onOk() {
                nextStep();
                dispatch(PropertyHandlers.linkMlsRequest({ mlsAgentData: data.selectedProperty, propertyId: selectedData.propertyId, ...(delegateSide ? {delegateSide, delegateOwnerId} : {}) }));
            },
            onCancel() {},
        });
        
    };

    const handleDelink = (property) =>{
        const obj ={
            propertyId : selectedData.propertyId,
            mlsPropertyId : property._id,
            ...(delegateSide ? {delegateSide, delegateOwnerId} : {})
        }
        Modal.confirm({
            title: 'Confirm',
            content: 'Are you sure you want to Unlink?',
            okText: "Yes",
            cancelText: "No",
            onOk() {
                dispatch(PropertyHandlers.handleDelinkPropertyModal(obj, triggerFunc));
            },
            onCancel() {},
        });
    }

    const checkPropertyAddress = (selectedProperty) => {
        const address = selectedData?.linkedPropertyIds?.[selectedData?.linkedPropertyIds?.length-1]?.propertyId?.fullAddress || selectedData?.address
        for(let value of selectedProperty){
            if(value.address !== address){
                return true;
            }
        }
        return false;
    }

    return (
        <>
            <Modal
                style={{ marginTop: "-50px" }}
                title={
                    <span
                    style={{ display: "flex", justifyContent: "center", color: "grey" }}
                    >
                     {isMLSVisible.editModal ? "LINKED MLS PROPERTIES" : "LINK MLS PROPERTIES"}
                    </span>
                }
                open={true}
                onCancel={handleMLSCancel}
                footer={false}
                width={600}
                maskClosable={((currentStep === 2) || getPropertySearchLoading || deLinkingMlsLoading) ? false : true}
                closeIcon={((currentStep === 2) || getPropertySearchLoading || deLinkingMlsLoading) ? true : false }
            >
                {
                    isMLSVisible.editModal ? <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "70vh" }}>
                        {deLinkingMlsLoading ? (
                        <Loading/>
                        ):(
                        <>
                            <div style={{maxHeight:"88%",overflow: "auto" }}>
                                {selectedData?.linkedPropertyIds && selectedData?.linkedPropertyIds?.length > 0 &&
                                    <div>
                                        {selectedData?.linkedPropertyIds.map((property, index) => {
                                        const logo1 = Mlslogos?.find((logo) => logo.type === property?.propertyId?.propertyType?.[0]);
                                        return (
                                            <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px", border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: "5px", padding: "8px",marginRight:"1rem" }}>
                                            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                            {logo1 && (
                                            <img
                                                src={logo1.src}
                                                alt={`Logo for ${property?.propertyId?.propertyType[0]}`}
                                                style={{ maxWidth: "80px", maxHeight: "50px", marginRight: "8px" }}
                                            />
                                            )}
                                            <div style={{ width: "350px"}}>
                                            <strong style={{ fontSize: "15px" }}>{property?.propertyId?.fullAddress}</strong>
                                            </div>
                                        </div>
                                        <div>
                                            <Button  onClick={() => handleDelink(property?.propertyId)} type="primary" ghost loading={deLinkingMlsLoading}>
                                                Unlink
                                            </Button>
                                        </div>
                                        </div>
                                        );
                                        })}
                                    </div>
                                }
                            </div>
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <div style={{ borderTop: "1px solid #ccc", width: "100%",marginBottom:"1.5rem" }}></div>
                                <div style={{ display: "flex", justifyContent:"space-evenly" }}>
                                    <Button onClick={() => handleLinkMoreMls()} type="primary" >
                                    Link Another MLS
                                    </Button>
                                </div>
                            </div>
                        </>
                        )}
                        
                    </div> : 
                    isMLSVisible.addModal && <div style={{ height: "70vh" }}>
                        <div>
                            <Steps current={currentStep} direction="horizontal">
                                {steps.map((step, index) => (
                                    <>
                                        <Step key={index} title={step.title} />
                                    </>
                                ))}
                            </Steps>
                        <div
                            style={{ marginTop: "20px", }}
                        >
                            {steps[currentStep]?.content}
                        </div>
                        <div
                            style={currentStep ===2 ? {display:"flex",justifyContent:"center"}:{
                            marginTop: "16px",
                            position: "absolute",
                            bottom: "0",
                            left: 0,
                            right: 0,
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "8px",
                            width: "90%",
                            margin: "auto",
                            }}
                        >
                            <Button
                                style={currentStep === 2 ?{display:"none"}:{ marginLeft: "8px",display:"block" }}
                                onClick={()=>{prevStep();dispatch(documentAction.resetSearchProperties());}}
                                disabled={(currentStep === 0) || getPropertySearchLoading}
                            >
                                Previous
                            </Button>
                            {
                                ((getPropertySearchData.length === 0) && !getPropertySearchLoading) && <Button
                                    style={ currentStep === 1 ? { display: "block" } : { display: "none" } }
                                    type="primary"
                                    onClick={() => form.submit()}
                                >
                                    Search
                                </Button>
                            }
                            {currentStep === 1 && data.selectedProperty.length !== 0 ? (
                                <Button
                                    onClick={handleLinkMls}
                                    type="primary"
                                    disabled={
                                        //results?.some((item) => item.toggle === true) ||
                                        data?.selectedMlsType?.length === 0 ||
                                        (currentStep === 1 &&
                                        getPropertySearchData.length === 0) ||
                                        (currentStep === 1 && data.selectedProperty.length === 0)
                                    }
                                >
                                    Link
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    onClick={() => {
                                    nextStep();
                                    }}
                                    style={
                                        currentStep === 2 ? { display: "none" } : { display: "block" }
                                    }
                                    disabled={
                                        data?.selectedMlsType?.some((item) => item.toggle === true) ||
                                        data?.selectedMlsType?.length === 0 ||
                                        (currentStep === 1 && getPropertySearchData.length === 0) ||
                                        (currentStep === 1 && data.selectedProperty.length === 0)
                                    }
                                >
                                    Next
                                </Button>
                            )}
                            {currentStep === 2 &&
                            <Button
                                type="primary"
                                onClick={handleFinish}
                                disabled={linkingMlsLoading}
                                style={
                                    currentStep === 2 ? { display: "block" } : { display: "none" }
                                }
                            >
                                Finish
                            </Button>}
                        </div>
                        </div>
                    </div>
                }
            </Modal>
        </>
    );
};
  
export default MLSModal;
