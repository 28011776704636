import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { clientAction } from "../state/actions";
import { Loading } from "../../Common/components/Loading";
import { UploadBtnBox } from "./UploadBtnBox";
import axios from "axios";
import { changeExtensiontoLowerCase } from "../../Common/utils/extraFunctions";

function PhotoComp({ id }) {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageModalVisible, setIsImageModalVisible] = useState(false);
  const [images, setImages] = useState("");
  const [fileText, setFileText] = useState({
    name: null,
    size: null,
    file: null,
    origin: null,
  });
  const [editDataNotess, setEditDataNotess] = useState("");
  const { memories, memoriesLoading, renderImage } = useSelector(
    (state) => state.client.memoriesReducer
  );
  const handleCancel = () => {
    setIsModalVisible(false);
    setIsImageModalVisible(false);
  };

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  useEffect(() => {
    if(id){
      dispatch(clientAction.getMemories({id, unmounted, source}));
    }

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [id, renderImage]);

  let handleDelete = (ele) => {
    dispatch(clientAction.deleteMemories(ele._id, id, message));
  };

  const onFinish = () => {
    let payload = { id: images._id, notes: editDataNotess };
    dispatch(clientAction.updateNoteApi(payload, id, message));

    setIsImageModalVisible(false);
  };

  const imageNotesChange = (ele) => {
    if (ele.notes === undefined) {
      setEditDataNotess("");
    } else {
      setEditDataNotess(ele.notes);
    }
  };
  const handleImageClick = (ele) => {
    setImages(ele);
    setIsImageModalVisible(true);
    imageNotesChange(ele);
    return;
  };
  const handleChange = (e) => {
    setEditDataNotess(e.target.value);
  };

  // Handling pasting an image
  function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }
  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" || file.type === "image/webp";
    if (!isJpgOrPng) {
      throw new Error("You can only upload JPG/PNG/JPEG/WEBP file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 100;
    if (!isLt5M) {
      throw new Error("Image must be smaller than 100MB!");
    }
    return isJpgOrPng && isLt5M;
  }

  useEffect(() => {
    if(id){
      let PasteEventFunction = (event) => {
        let count = 0;
        let error = null;
        var items = (event.clipboardData || event.originalEvent.clipboardData)
          .items;
        for (let index in items) {
          var item = items[index];
          if (item.kind === "file" && count === 0) {
            var blob = item.getAsFile();
            try {
              beforeUpload(blob);
            } catch (err) {
              error = err.message;
              break;
            }
            var reader = new FileReader();
            const ide = Math.floor(10000 + Math.random() * 90000);
            const imageName = "pastedImage" + ide;
            var updatedBlob = new File([blob], imageName, { type: blob.type });
  
            reader.onload = function (event) {
              // Handle image loading
            };
            reader.readAsDataURL(blob);
            count = +count + 1;
          }
        }
        if (error) {
          message.error(error);
        } else if (updatedBlob) {
          let existingId = id;
          let ide = { id: existingId };
          dispatch(clientAction.postImage({fileData:updatedBlob, id:ide}));
          // dispatch(clientAction.getMemories(id));
        }
      };
      let getModal = document?.getElementById(id);
      getModal?.addEventListener("paste", PasteEventFunction);
      return () => {
        getModal?.removeEventListener("paste", PasteEventFunction);
      };
    }
  }, [id]);

  let handleCustome = (data) => {
    const reader = new FileReader();
    reader.readAsDataURL(data.file);
    const fileName = changeExtensiontoLowerCase(data?.file?.name)
    reader.onload = () => {
      setFileText({
        name: fileName,
        size: bytesToSize(data.file.size),
        file: reader.result,
        origin: "notBase64",
      });
    };
    reader.onerror = (error) => {
      return error;
    };
    dispatch(clientAction.uploadImageforProfile(data.file, "notBase64", id));
  };

  return (
    <div id={id} style={{ minHeight: "100px" }}>
      {memoriesLoading ? (
        <div style={{ marginTop: "30%" }}>
          <Loading />
        </div>
      ) : (
        <UploadBtnBox
          id={id}
          memories={memories}
          handleImageClick={handleImageClick}
          handleDelete={handleDelete}
          imageModalVisible={imageModalVisible}
          handleCancel={handleCancel}
          handleCustome={handleCustome}
          editDataNotess={editDataNotess}
          handleChange={handleChange}
          onFinish={onFinish}
          images={images}
        />
      )}
    </div>
  );
}
export { PhotoComp };
