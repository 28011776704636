import { Button } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { PropertyHandlers } from "../state/actions";

const EditableTableCell = ({ description, taskId, getIndividualTransactionData, agentType, delegateSide, delegateOwnerId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(description);
  const dispatch = useDispatch();

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleAddNotes = () => {
    let urlData = {
      description: value,
    }
    dispatch(
      PropertyHandlers.editCustomTask({taskId, urlData, offerId: getIndividualTransactionData?.offerId, agentType, delegateSide, delegateOwnerId} ),
    );
    setIsEditing(false);
  }

  return (
    <td
      style={{
        fontWeight: "bolder",
        width: "25%",
        position: "relative",
      }}
      onClick={!isEditing ? handleEdit : undefined}
    >
      {isEditing ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <textarea
            autoFocus
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={handleAddNotes}
            style={{
              width: "100%",
              resize: "none",
              fontWeight: "bold",
              fontSize: "14px",
              marginRight: "8px",
              minHeight:"100px"
            }}
          />
          <Button
            type="primary"
            onClick={handleAddNotes}
            style={{
              marginRight: "4px",
              padding: "5px 8px",
              backgroundColor: "#1890ff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Save
          </Button>
        </div>
      ) : (
        value
      )}
    </td>
  );
};

export default EditableTableCell;
