import { toolsConstants } from "../action-types";

const initialState = {
  getClauseData: [],
  getClausesLoading: false,
  getClausesError: false,

  getDocsData: [],
  getdocsLoading: false,
  getDocsError: false,

  getEmail_TemplateData: [],
  getEmail_TemplateLoading: false,
  getEmail_TemplatesError: false,
  getEmail_TemplateErrorMsg: null,

  addClausesData: [],
  addClauseLoading: false,
  addClauseerror: false,

  SenMailforClauseLoading : false,

  FJGGAcessData : [],
  FJGGAcessDataLoading : false,
  FJGGAcessDataError : false,

  updateData: []
};

const DocumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolsConstants.GET_CLAUSE_LOADING:
      return {
        ...state,
        getClausesLoading: true,
        getClausesError: false,
        getClauseData: action?.pageNumber === 1 ? [] : [...state.getClauseData],
      };
    case toolsConstants.GET_CLAUSE_SUCCESS:
      return {
        ...state,
        getClauseData: [...state.getClauseData, ...action.getClauseData],
        getClausesLoading: false,
        getClausesError: false,
      };
    case toolsConstants.GET_CLAUSE_FAILURE:
      return {
        ...state,
        getClausesLoading: false,
        getClausesError: true,
      };

    case toolsConstants.GET_DOCUMENTS_LOADING:
      return {
        ...state,
        getClausesLoading: true,
        getClausesError: false,
        getDocsData: action?.pageNumber === 1 ? [] : [...state.getDocsData],
      };
    case toolsConstants.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        getDocsData: [...state.getDocsData, ...action.getDocsData],
        getClausesLoading: false,
        getClausesError: false,
      };
    case toolsConstants.GET_DOCUMENTS_FAILURE:
      return {
        ...state,
        getClausesLoading: false,
        getClausesError: true,
      };

    case toolsConstants.ADD_DOCUMENTS_LOADING:
      return {
        ...state,
        addClauseLoading: true,
        addClauseerror: false,
      };
    case toolsConstants.ADD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        getClauseData: action.getClauseData,
        addClauseLoading: false,
        addClauseerror: false,
      };
    case toolsConstants.ADD_DOCUMENTS_FAILURE:
      return {
        ...state,
        addClauseLoading: false,
        addClauseerror: true,
      };
    case toolsConstants.GET_EMAIL_TEMPLATE_LOADING:
      return {
        ...state,
        getEmail_TemplateLoading: true,
        getEmail_TemplatesError: false,
      };
    case toolsConstants.GET_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        getEmail_TemplateData: action.getEmail_Templatedata,
        getEmail_TemplateLoading: false,
        getEmail_TemplatesError: false,
      };
    case toolsConstants.GET_EMAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        getEmail_TemplateLoading: false,
        getEmail_TemplatesError: true,
        getEmail_TemplateErrorMsg: action.errorMsg
      };
      case toolsConstants.SEND_MAIL_FOR_CLAUSES_LOADING:
        return {
          ...state,
          SenMailforClauseLoading:true,
        };
      case toolsConstants.SEND_MAIL_FOR_CLAUSES_SUCCESS:
        return {
          ...state,
          SenMailforClauseLoading:false,
        };
      case toolsConstants.SEND_MAIL_FOR_CLAUSES_FAILURE:
        return {
          ...state,
          SenMailforClauseLoading:false,
        };
        case toolsConstants.GET_ACCESS_TO_FJGG_DOCANDCLAUSES_LAODING:
            return {
              ...state,
              FJGGAcessDataLoading:true,
            };
          case toolsConstants.GET_ACCESS_TO_FJGG_DOCANDCLAUSES_SUCCESS:
            return {
              ...state,
              FJGGAcessData:action.FJGGAccessData,
              FJGGAcessDataLoading:false,
            };
          case toolsConstants.GET_ACCESS_TO_FJGG_DOCANDCLAUSES_ERROR:
            return {
              ...state,
              FJGGAcessDataError:action.error,
            };
    case toolsConstants.UPDATE_SUCCESS:
      return {
        ...state,
        updateData: [],
      };

    default:
      return state;
  }
};

export { DocumentReducer };
