import { Button, Form, Input, Modal } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux';
import { PropertyHandlers } from '../state/actions';

const CreateFolderModal = ({setCreateFolderModalOpen, createFolderModalOpen, getCoordinateItem, delegate, agentDetails, setFolderName, subFolder, ele }) => {
    const dispatch = useDispatch();
// console.log("getCoordinateItem inside createFolder", getCoordinateItem);
    const handleCreateFolder = (values) => {
        const { folderName } = values;
        if (folderName.trim()) {
          const newFolderData = {
            name: folderName?.trim(),
            storageType: "FOLDER",
            parentId : subFolder ? ele?._id : null,
            ...(getCoordinateItem?.offerId ? { offerId: getCoordinateItem?.offerId } : {}),
            ...(getCoordinateItem?.propertyId ? { propertyId: getCoordinateItem?.propertyId } : {}),
            ...(delegate ? { delegateSide: (getCoordinateItem?.isProperty || getCoordinateItem?.isErts) ? "SELLER" : "BUYER", delegateOwnerId: agentDetails?._id } : {}),
            section: "PROPERTY_DOCS",
            openFor: getCoordinateItem?.isProperty || getCoordinateItem?.isErts ? "SELLER_AGENT" : "BUYER_AGENT",
          };
          setFolderName(newFolderData);
          setCreateFolderModalOpen(false);
          dispatch(PropertyHandlers.createFolderFun({ newFolderData }));
        }
      };

  return (
    createFolderModalOpen &&
    <Modal
        title={<span style={{ display: "flex", justifyContent: "center", color: "#178DFA",fontWeight:"bold",fontSize:"22px" }}>Create New Folder</span>}
        open={true}
        onCancel={() => setCreateFolderModalOpen(false)}
        footer={null}
        style={{ top: "100px", overflow: "hidden" }}
        width={450}
      >
        <Form
          layout="vertical"
          onFinish={handleCreateFolder}
        >
          <Form.Item
            name="folderName"
          >
            <Input placeholder="Enter Folder Name" />
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
  )
}

export default CreateFolderModal
