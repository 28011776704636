import { ERTSConstants } from "./action-types";

const initialState = {
  ERTS_Loading: false,
  ERTS_Error: false,
  ERTSData: {},
};

const ERTSReducer = (state = initialState, action) => {
  switch (action.type) {
    case ERTSConstants.POST_PDF_FILE_LOADING:
      return {
        ...state,
        ERTS_Loading: true,
      };
    case ERTSConstants.POST_PDF_FILE_ERROR:
      return {
        ...state,
        ERTS_Loading: false,
        ERTS_Error: true,
      };
    case ERTSConstants.POST_PDF_FILE_SUCCESS:
      return {
        ...state,
        ERTSData: action.ERTSData,
        ERTS_Loading: false,
      };
    case ERTSConstants.RESET_ERTS_DATA:
      return {
        ...state,
        ERTS_Loading: false,
        ERTSData: {},
      };
    case ERTSConstants.CHECK_IS_MARK_EIBNER_BROKERAGE_LOADING:
      return {
        ...state,
        checkIsMarkEibnerBrokerageLoading: true,
        checkIsMarkEibnerBrokerageError: false,
      };
    case ERTSConstants.CHECK_IS_MARK_EIBNER_BROKERAGE_SUCCESS:
      return {
        ...state,
        checkIsMarkEibnerBrokerageLoading: false,
        checkIsMarkEibnerBrokerageError: false,
        MEBrokerageAccess: action.MEBrokerageAccess,
      };
    case ERTSConstants.CHECK_IS_MARK_EIBNER_BROKERAGE_ERROR:
      return {
        ...state,
        checkIsMarkEibnerBrokerageError: action.checkIsMarkEibnerBrokerageError,
        checkIsMarkEibnerBrokerageLoading: false,
      };
    default:
      return state;
  }
};

export { ERTSReducer };
