import React, { useEffect, useState } from "react";
import { Typography } from "antd";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toolsAction } from "../state/actions";
import { Loading } from "../../Common/components/Loading";
import { ClauseModal } from "./ClauseModal";
import { ClausedataList } from "./ClausedataList";
const { Title } = Typography;

const ClausesList = ({searchText, pathname, setPage, page, setTriggerAPI}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const dispatch = useDispatch();

  const { getClauseData, getClausesLoading } = useSelector(
    (state) => state.ToolReducers.DocumentReducer,
    shallowEqual
  );

  // updating the docs name and clauses
  const onFinish = () => {
    dispatch(toolsAction.updateDocsClause(selectedData, "CLAUSE", setTriggerAPI));
    setShowModal(false);
  };
  const handleEdit = (value) => {
    setSelectedData(value);
    setShowModal(true);
  };
  const handleChange =(option,values)=>{
    values.creationType  = option;
    dispatch(toolsAction.updateDocsClause(values, "CLAUSE", setTriggerAPI));
  }
  const handleDelete = (id) => {
    dispatch(toolsAction.deleteDocsClause(id, "CLAUSE", setTriggerAPI));
  };

  return (
    <>
      <ClauseModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        onFinish={onFinish}
      />
      {getClausesLoading && page <=1 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "70px",
          }}
        >
          <Loading />
        </div>
      ) : (
        <ClausedataList
          getClauseData={getClauseData}
          getClausesLoading={getClausesLoading}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleChange={handleChange}
          searchText={searchText}
          page={page}
          setPage={setPage}
          setTriggerAPI={setTriggerAPI}
        />
      )}
    </>
  );
};

export default ClausesList;
