/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Button, Row, Input, List } from "antd";
import React, { useState, useEffect } from "react";
import { UserOutlined, DeleteTwoTone } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { EmptyData } from "../../Common/components/EmptyData";
import { generateInitialsFromObj } from "../../Common/utils/extraFunctions";
import { useMediaQuery } from "react-responsive";

const ManalAddClient = ({ source, currentlySelectedData, handleDataChange, clientsArray ,addPersons,addSigners}) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [selectedRows, setSelectesRows] = useState([]);
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    if (currentlySelectedData?.isCorp) {
      setSelectesRows(currentlySelectedData.signers);
    } else {
      setSelectesRows(clientsArray?.filter((ele) => !ele.isCorp));
    }
  }, [currentlySelectedData]);

  const handleManualClientAdd = () => {
    let clients = clientsArray || [];
    let clientInfo = {
      fullName: clientName?.trim(),
      personId: null,
      isCorp: false,
      signers: [],
      key: uuidv4(),
    };
    if (currentlySelectedData?.isCorp) {
      let newDataIndex = clients.findIndex((el) => el.key === currentlySelectedData.key);
      if (newDataIndex !== -1) {
        clients = [...clients.slice(0, newDataIndex), { ...clients[newDataIndex], signers: [...(clients[newDataIndex].signers || []), clientInfo] }, ...clients.slice(newDataIndex + 1)];
      }
    } else {
      clients = [...clients, clientInfo];
    }
    setSelectesRows([...selectedRows, clientInfo]);
    setClientName("");
    handleDataChange({Buyers:clients});
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleManualClientAdd();
    }
  };

  const handleDeleteClient = (element) => {
    let clients = clientsArray;
    if (clients) {
      if (currentlySelectedData?.isCorp) {
        let newDataIndex = clients.findIndex((el) => el.key === currentlySelectedData?.key);
        if (newDataIndex !== -1) {
          clients[newDataIndex].signers = clients[newDataIndex].signers.filter((signer) => signer.key !== element.key);
          setSelectesRows(clients[newDataIndex].signers);
        }
        // console.log("newDataIndex", newDataIndex);
      } else {
        clients = clients.filter((el) => {
          if (element.key) {
            return el.key !== element.key;
          } else {
            return el.personId !== element.personId;
          }
        });
        setSelectesRows(clients?.filter((ele) => !ele.isCorp));
      }

      handleDataChange({Buyers:clients});
    }
  };

  return (
    <div>
      {((addSigners && source === "CORP")||(addPersons))&&(
        <div style={{ display: "flex", gap: "10px", marginTop: source === "CORP"?"0px":"10px",marginRight:"10px" }}>
          <Input placeholder={source === "CORP" ? "Enter Signer Name" : "Enter Person Name"} name="no-event" value={clientName} onChange={(e) => setClientName(e.target.value)} onKeyPress={handleKeyPress} />
          <Button type="primary" onClick={handleManualClientAdd} disabled={!clientName?.trim()}>
            Add
          </Button>
        </div>
      )}
      <Row
        style={
          {
            // border: "2px solid red",
          }
        }
      >
        {selectedRows?.length > 0 ? (
          <div
            style={{
              width: isMobile ? "350px" : "90%",
              minHeight: isMobile ? "20vh" : source==="CORP"?"30vh":"10vh",
              maxHeight:source==="CORP"?"30vh":"none",
              overflowY:source==="CORP"?"auto":"hidden",
              overflowX: "hidden",
              margin: isMobile ? "10px -20px" : "10px 0%",
            }}
          >
            <List
              style={{
                margin: isMobile ? "-5px 0px" : "10px 0px",
                width: isMobile?"90%":"96%",
              }}
              dataSource={selectedRows}
              renderItem={(item) => {
                const intials = generateInitialsFromObj(item);
                return (
                  <List.Item key={item._id} style={{ margin: isMobile ? "-5px 20px" : "-10px 0%" }}>
                    <List.Item.Meta
                      avatar={
                        intials ? (
                          <Avatar size={45} style={{ background: "#085191" }}>
                            {intials}
                          </Avatar>
                        ) : (
                          <Avatar size={45} icon={<UserOutlined />} style={{ background: "#085191" }} />
                        )
                      }
                      title={<span style={{ fontSize: "15px", display: "inline-block", verticalAlign: "middle", lineHeight: "45px", maxWidth: isMobile ? "160px" : "auto", whiteSpace: "nowrap", minWidth: isMobile ? "160px" : "70%", overflow: "hidden", textOverflow: "ellipsis", textTransform: "capitalize" }}> {item?.fullName || ""}</span>}
                    />
                    <span onClick={() => handleDeleteClient(item)} style={{ cursor: "pointer" }}>
                      <DeleteTwoTone style={{ fontSize: "medium",marginLeft:"8px" }} />
                    </span>
                  </List.Item>
                );
              }}
            />
          </div>
        ) : (
          <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center",marginTop:source==="CORP"?"100px":'0'}}>
            <EmptyData />
          </div>
        )}
      </Row>
    </div>
  );
};

export default ManalAddClient;
