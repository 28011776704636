import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../Common/components/Loading";
import { Col } from "antd";
import Sider from "antd/lib/layout/Sider";
import { calendarHandle } from "../state/actions";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from 'react-router-dom';

const apiURL = process.env.REACT_APP_ADMIN_BASE_URL;

function WeekView({viewType, persistCalendar}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 700 })
  const isTab = useMediaQuery({minWidth:701, maxWidth: 1000 })
  const { urlShorthandData, urlShorthandLoading } = useSelector(
    (state) => state.calendarReducer
  );
  const handleIframe = () => {
    const iframe = document.getElementById("iframe-calendar");
    iframe.contentWindow.postMessage(
      "Please Send the date back",
      process.env.REACT_APP_ADMIN_BASE_URL
    );
  };

  useEffect(() => {
    const handleMessage = (event) => {
      // only comes from admin
      if (event.origin !== apiURL) return;
      const { action, route, state, persistCalendar } = event.data;

      if (action === "navigate" && route) {
        if(persistCalendar){
          localStorage.setItem("persistCalendar", JSON.stringify(persistCalendar));
        }
        history.push({ pathname: route, state: { refresh: true, ...(state && state) } });
      }
    };
    window.addEventListener("message", handleMessage);

    let urlData = {
      urlData: {
        offerId: null,
        key: localStorage.getItem("userAuth"),
        redirectTo: "webApp",
        ...(persistCalendar && {persistCalendar})
      },
    };
    dispatch(calendarHandle.urlShorthandApiCalling(urlData));
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [dispatch]);
  return (
    <>
      <div
        className="landing_page"
        style={ (viewType === "week") ? { width:isMobile ? "900px" :isTab ? "1000px" : "100%", minWidth:isMobile ? "900px" :isTab ? "1000px" : "" , height: "calc(100vh - 40px)" }: {}}
      >
        <Col span={6} xs={24} sm={24} md={24} lg={24} xl={24}>
          <Sider
            className="site-layout-background mozScrollbar"
            theme={"light"}
            width={"100%"}
            style={{
              // padding: "1rem",
              height: "calc(87vh)",
              overflow: "auto",
              // border: "1px solid rgba(0, 0, 0, 0.2)",
              // borderRadius: "8px",
              backgroundColor: "#fff",
              // boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
              transition: "0.3s",
              position: "relative",
            }}
          >
            {urlShorthandLoading ? (
              <Loading />
            ) : (
              <iframe
                src={`${apiURL}/calendar/${urlShorthandData}?viewType=${viewType}&source=HOME_CALENDAR`}
                height={"80%"}
                width="100%"
                title="calendar"
                frameBorder="0"
                id="iframe-calendar"
                onLoad={handleIframe}
              />
            )}
          </Sider>
        </Col>
      </div>
    </>
  );
}
export { WeekView };
