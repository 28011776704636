import React, { useState } from 'react';
import { message, Modal } from 'antd';
import RecursiveFolder from './RecursiveFolder';
import { useDispatch } from 'react-redux';
import { PropertyHandlers } from '../state/actions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CreateFolderModal from './CreateFolderModal';

const PropertyDocFolder = ({ ele, documents, role, item, handleOPenDocs, handlePropertyDocs, handleShareUnshareFileConfirm, handleToggleDashboard, handleDeletePropertyDocs, isSmallScreen, propertyDocsWithType, setPropertyDocsWithType, folderName, setFolderName, handleDragOver, handleDrop, delegate, setCreateFolderModalOpen, createFolderModalOpen, agentDetails, setSelectedFiles,  handleDragStart, selectedFiles}) => {
    const dispatch = useDispatch()
    const [isNameModalOpen, setIsNameModalOpen] = useState({
        createFolder: false,
        editName: false
    })
    const [hoveredRow, setHoveredRow] = useState(null);

    const handleSelectFile = (fileId, contractId, selectedParentId) => {
        if (contractId) {
            let updatedOfferDocs = selectedFiles?.offerDocs?.filter(((el) => el?.offerDocId === fileId))?.length ? selectedFiles?.offerDocs?.filter((el) => el?.offerDocId !== fileId) : [...selectedFiles?.offerDocs, {offerDocId: fileId, selectedParentId:selectedParentId}]
            setSelectedFiles({ ...selectedFiles, offerDocs: updatedOfferDocs })
        } else {
            let updatedPropDocs = selectedFiles?.propertyDocs?.filter(((el) => el?.propertyDocId === fileId))?.length ? selectedFiles?.propertyDocs?.filter((el) => el?.propertyDocId !== fileId) : [...selectedFiles?.propertyDocs, {propertyDocId: fileId, selectedParentId:selectedParentId}]
            setSelectedFiles({ ...selectedFiles, propertyDocs: updatedPropDocs })
        }
    };

    const handleAddSubFolder = () => {
        setIsNameModalOpen({ ...isNameModalOpen, createFolder: true });
    }

    const handleFolderDelete = (element) => {
        if (element?.nestedItems?.length) {
            message.info("There are some files in the folder, move them out before deleting the folder.")
        } else {
            Modal.confirm({
                title: "Are you sure you want to delete this folder?",
                okText: "Yes",
                cancelText: "No",
                icon: <ExclamationCircleOutlined  style={{  color: "orange" }}/>,
                onOk() {
                    dispatch(PropertyHandlers.deletePropertyDocFolder(element?._id))
                },
                onCancel() { }
            })
        }
    }

    return (
        <>
            {isNameModalOpen?.createFolder && <CreateFolderModal setCreateFolderModalOpen={setIsNameModalOpen} createFolderModalOpen={isNameModalOpen} getCoordinateItem={item} delegate={delegate} agentDetails={agentDetails} setFolderName={setFolderName} subFolder={true} ele={ele} />}

            <tr>
                <td colSpan={5} style={{ padding: 0, }}>
                    {ele && (
                        <RecursiveFolder
                            item={ele}
                            getCoordinateItem={item}
                            level={0}
                            onAddSubFolder={handleAddSubFolder}
                            onDeleteItem={handleFolderDelete}
                            handleSelectFile={handleSelectFile}
                            handleDragOver={handleDragOver}
                            handleDrop={handleDrop}
                            propertyDocsWithType={propertyDocsWithType}
                            setPropertyDocsWithType={setPropertyDocsWithType}
                            role={role}
                            handleOPenDocs={handleOPenDocs}
                            handlePropertyDocs={handlePropertyDocs}
                            handleShareUnshareFileConfirm={handleShareUnshareFileConfirm}
                            handleToggleDashboard={handleToggleDashboard}
                            handleDeletePropertyDocs={handleDeletePropertyDocs}
                            isSmallScreen={isSmallScreen}
                            folderName={folderName}
                            setFolderName={setFolderName}
                            delegate={delegate}
                            setCreateFolderModalOpen={setCreateFolderModalOpen}
                            createFolderModalOpen={createFolderModalOpen}
                            agentDetails={agentDetails}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            hoveredRow={hoveredRow}
                            handleDragStart={handleDragStart}
                            setHoveredRow={setHoveredRow}
                            documents={documents}
                        />
                    )}
                </td>
            </tr>
        </>
    );
};

export default PropertyDocFolder;

