import { Avatar, Badge, List, Tooltip } from "antd";
import React from "react";
import { DeleteTwoTone, UserAddOutlined ,HomeOutlined, StarFilled, CheckCircleOutlined} from "@ant-design/icons";
import styles from "../style/landingPage.module.css";
import { EmptyData } from "../../Common/components/EmptyData";
import { FaBirthdayCake } from "react-icons/fa";
const ReminderList = ({
  currentReminderData,
  showDeleteConfirm,
  handleListItemClick,
  section,
  currentScheduleData,
}) => {
  
 return (
    <>
      {currentReminderData?.length > 0 ? (
        <div style={{width:"100%"}}>
          <List
          itemLayout="horizontal"
          dataSource={currentReminderData}
          renderItem={(item) => (
            <List.Item
              style={{
                width: "100%",
                cursor: "pointer",
              }}
            >
              <List.Item.Meta
                onClick={() => handleListItemClick(item,"reminder")}
                avatar={
                  <>
                    {item.type === "BIRTHDAY" ? (
                      item?.profileImg?.original ? (
                        <Avatar
                          size={52}
                          src={item?.profileImg?.original}
                        />
                      ) : item?.firstName ? (
                        <Avatar size={52} style={{ background: "#085191" }}>
                          {(item?.firstName
                            ? item?.firstName[0]?.toUpperCase()
                            : "") +
                            "" +
                            (item?.lastName
                              ? item?.lastName[0]?.toUpperCase()
                              : "")}
                        </Avatar>
                      ) : (
                        <Avatar
                          shape="circle"
                          size={52}
                          style={{
                            background: "#085191",
                            // fontSize: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <UserAddOutlined
                            style={{
                              // fontSize: "20px",
                              fill: "#085191",
                              color: "white",
                            }}
                          />
                        </Avatar>
                      )
                    ) : item?.taggedPersonIds?.[0]?.profileImg?.original ? (
                      <Avatar
                        size={52}
                        src={item?.taggedPersonIds?.[0]?.profileImg?.original}
                      />
                    ) : item?.taggedPersonIds?.[0]?.firstName ? (
                      <Avatar size={52} style={{ background: "#085191" }}>
                        {(item?.taggedPersonIds?.[0].firstName
                          ? item?.taggedPersonIds?.[0].firstName[0].toUpperCase()
                          : "") +
                          "" +
                          (item?.taggedPersonIds?.[0].lastName
                            ? item?.taggedPersonIds?.[0].lastName[0].toUpperCase()
                            : "")}
                      </Avatar>
                    ) : (
                      <Avatar
                        shape="circle"
                        size={52}
                        style={{
                          background: "#085191",
                          // fontSize: "24px",
                          display: "flex",
                          alignItems: "center",
                          // height: "52px",
                        }}
                      >
                        <UserAddOutlined
                          style={{
                            // fontSize: "24px",
                            fill: "#085191",
                            color: "white",
                          }}
                        />
                      </Avatar>
                    )}
                  </>
                }
                title={
                  <div style={{display:"flex",justifyContent:"space-between",alignItems: "center",width:"100%"}}>
                    <div
                    style={{
                      fontSize: "18px",
                      width: "100%",
                    }}
                    className={styles.singleTextWraping}
                    onClick={() => item?.type === "REMINDER" && handleListItemClick(item,"reminder")}
                  >
                    <Tooltip placement="top" title={item?.type === "BIRTHDAY" ? item?.fullName : item?.title}>
                        <span style={{ position: "relative", display: "inline-block" }}>
                          {item?.type === "BIRTHDAY" ? item?.fullName : item?.title}
                          {item?.completedDateMili && (
                            <CheckCircleOutlined
                              style={{
                                color: "green",
                                fontSize: "12px",
                                position: "absolute",
                                top: "6px",
                                right: "-16px",
                              }}
                            />
                          )}
                        </span>
                    </Tooltip>
                  </div>
                    {item?.type !== "BIRTHDAY" && <div style={{margin:"-10px 8px 0 0"}}>
                              {" "}
                              <DeleteTwoTone
                                style={{
                                  color: "black",
                                  fontSize: 16,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  showDeleteConfirm(item,"reminder")
                                }}
                              />
                          </div>}
                  </div>
                }
                description={
                  <div
                    style={{
                      marginTop: "-5px",
                      display: "flex",
                      alignItems: "center",
                      gap:"10px",
                      width:"100%"
                    }}
                  >
                    {item?.type === "BIRTHDAY" ? (
                    <>
                      <div>
                        <FaBirthdayCake style={{ fill:"#000000"}}/>
                      </div>
                      <div
                        style={{
                          marginTop: "-30px",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "90px",
                        }}
                      >
                        <Badge
                          count={"Birthday"}
                          style={{ marginBottom:"7px" ,color:"black",background:"lightgreen"}}
                        />{" "}
                      </div>
                    </>
                   ) :item?.type === "CONTRACT_REMINDER"? (
                      <div className={styles.singleTextWraping} style={{
                           color:"#333333",
                           }}><HomeOutlined  style={{ fill:"#000000",margin:"0 8px 20px 0"}}/> {item?.fullAddress}
                      </div>
                      ) : item?.type === "REMINDER" ? (
                          <div style={{display:"flex",justifyContent:"space-between",alignItems: "center",width:"100%"}}>
                            <p style={{
                              color: "#333333"
                            }} className={styles.singleTextWraping} onClick={() => handleListItemClick(item,"reminder")}>{item?.description}</p>
                          </div>
                      ) : ("")}
                  </div>
                }
              />
            </List.Item>
          )}
        />
        </div>
      ) : (
        section !== "HOME" && !currentScheduleData?.length && <div style={{ marginTop: "20%" }}>
          <EmptyData />
        </div>
      )}
    </>
  );
};

export default ReminderList;
