/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Form,
    Input,
    List,
    Modal,
    Tabs,
    Tooltip,
    Avatar,
    Collapse,
    message,
  } from "antd";
 import styles from "../../DocumentSection/style/EditSellerBuyerCorp.module.css"
  import React, { useEffect, useState } from "react";
  import { CreateCustomerDrawer } from "../../DocumentSection/components/CreateCustomerDrawer";
  import {
    UserAddOutlined,
    SearchOutlined,
    UserOutlined,
    DeleteTwoTone,
    CaretRightOutlined,
    PlusCircleFilled,
    ExclamationCircleOutlined,
  } from "@ant-design/icons";
  import { EmptyData } from "../../Common/components/EmptyData";
  import { useDispatch, useSelector } from "react-redux";
  import { documentAction } from "../../DocumentSection/state/actions";
  import { clientAction } from "../../Client/state/actions";
  import { generateFullNameFromObj, generateInitialsFromObj } from "../../Common/utils/extraFunctions";
  import { v4 as uuidv4 } from "uuid";
  import { useMediaQuery } from "react-responsive";
  import { PropertyHandlers } from "../state/actions";
  import { ClientList } from "../../Client/components/ClientList";
import EachCorpSigner from "../../DocumentSection/components/EachCorpSigner";


  const UserDetailsModal = ({ state, setState, item, section, filterSort, delegateOwnerId }) => {
    const delegate =  (section === "DELEGATE");
    const { TabPane } = Tabs;
    const { Panel } = Collapse;
    const { confirm } = Modal;
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ maxWidth: 480 });

    const [showClientListModal, setShowClientListModal] = useState(false);
    const [page, setPage] = useState([1]);
    const [isfilterArchive, setIsFilterArchive] = useState("");
    const [showSearchInput, setShowSearchInput] = useState(false);
    const [value, setValue] = useState("");
    const [formVisibility, setFormVisibility] = useState({
      addPersons: false,
      addCorps: false,
      addSigners: false,
    });
  const agentDetails =  item?.currentAgentId;

    let { clientsArray, drawerVisible, entityName, currentlySelectedCorpData } = useSelector(
      (state) => state.documentSection.CorpReducer,
    );
 
    let agentType = item?.isProperty ? "SELLER_AGENT":"BUYER_AGENT";
    let source = agentType === "BUYER_AGENT" ? "BUYER" : "SELLER";
    let sourceConditon =
      !item?.persistId &&
      agentType === "SELLER_AGENT"
        ? "EDIT"
        : "NO_EDIT";

    const handleCancel = () => {
      setState({ ...state, isUseDetailModal: false,isSignersModal: false });
    };

    let drawerClose = () => {
      dispatch(documentAction.clearCustomerData());
      dispatch(documentAction.setCorpState({ drawerVisible: false }));
    };

    const handleTabClick = (key) => {
      if (key === "2") {
        dispatch(documentAction.setCorpType(true));
      } else {
        dispatch(documentAction.setCorpType(false));
      }
    };

    const handleInputOrSearchClick = (element) => {
      setShowClientListModal(true);
      dispatch(clientAction.clearGetClientData());
      setPage([1]);
    };

    const handleCreateUserClick = (element) => {
      dispatch(documentAction.setCorpState({ drawerVisible: true }));
    };

    const handleDeleteClientOrCorp = (element, type, item) => {
      if (type === "CORP") {
        handleCorpDelete(element);
        return;
      } else if (type === "SIGNERS") {
        let newDataIndex = clientsArray.findIndex((el) => el.key === element.key);
        if (newDataIndex !== -1) {
          clientsArray[newDataIndex].signers = clientsArray[
            newDataIndex
          ].signers.filter((signer) => signer.personId !== item.personId);
        }
      } else {
        clientsArray = clientsArray.filter(
          (el) => el.personId !== element.personId,
        );
      }
      dispatch(documentAction.setCorpState({ clientsArray: clientsArray }));
    };

    const handleCorpDelete = (element = {}) => {
      Modal.confirm({
        title: `Are you sure you want to remove ${element.fullName} from this form?`,
        okText: "Yes",
        cancelText: "No",
        icon: <ExclamationCircleOutlined />,
        content:
          "Note: This will also remove all the signers associated with it.",
        onOk() {
          let newData = clientsArray.filter((el) => el.key !== element.key);
          dispatch(documentAction.setCorpState({ clientsArray: newData }));
        },
        onCancel() {},
      });
    };

    const handleCorpAdd = () => {
      dispatch(documentAction.setCorpType(true));
      dispatch(documentAction.setCorpState({ entityName: "" }));
      let corpInfo = {
        fullName: entityName?.trim(),
        isCorp: true,
        key: uuidv4(),
        signers: [],
      };

      let newData = [...(clientsArray || []), corpInfo];
      dispatch(documentAction.setCorpState({ clientsArray: newData }));
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        handleCorpAdd();
      }
    };

    const handleEntityNameChange = (e) => {
      dispatch(documentAction.setCorpState({ entityName: e.target.value }));
    };

    const handleAddSigners = (element) => {
      setShowClientListModal(true);
      dispatch(
        documentAction.setCorpState({ currentlySelectedCorpData: element }),
      );
    };

    const handleAddSellers = () => {
      confirm({
        title:
          clientsArray?.length > 0
            ? `Are you sure you want to continue with the added sellers?`
            : `Are you sure you want to continue with no sellers?`,
        content: "",
        okText: "Yes",
        cancelText: "No",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          let data = {
            addToProperty: true,
            propertyId: item?._id,
            sellerIds: clientsArray,
            ...(delegate ? {delegateSide: (item.isProperty)?"SELLER":"BUYER", delegateOwnerId: agentDetails?._id} : {}),
          };
          dispatch(PropertyHandlers.editSellers({data, filterSort}));
          handleCancel();
        },
        onCancel() {},
      });
    };

    // when the user select any client in the clientList
    const handleClientSelected = (selectedClient) => {
        if (!selectedClient.email) {
          message.error("Please add the client's email to proceed!");
          return;
        }
        const clients = clientsArray || [];
        if (clients.length >= 10) {
          message.error(`Maximum number of clients already added.`);
          return;
        }
        const isCorp = currentlySelectedCorpData?.isCorp;
        const existingIndex = clientsArray.findIndex((el) => el.key === currentlySelectedCorpData?.key);
        if (isCorp && existingIndex !== -1) {
          const existingSigners = clientsArray[existingIndex].signers || [];
          if (existingSigners.some((el) => el.personId === selectedClient._id)) {
            message.error(`Client already exists in the signers list.`);
            return;
          }
          clientsArray[existingIndex].signers = [...(existingSigners || []), { fullName: generateFullNameFromObj(selectedClient), personId: selectedClient._id }];
          dispatch(documentAction.setCorpState({ clientsArray }));
          message.success("Seller added Successfully!");
        } else if (!isCorp && clients.some((client) => client.personId === selectedClient._id)) {
          message.error(`Client already exists in the sellers list.`);
          return;
        } else {
          clientsArray.push({
            fullName: generateFullNameFromObj(selectedClient),
            personId: selectedClient._id,
            isCorp: false,
            signers: [],
          });
          dispatch(documentAction.setCorpState({ clientsArray }));
          message.success("Seller added Successfully!");
        }
      };

    const handleCancelUserlist = () => {
        setShowClientListModal(!showClientListModal);
        dispatch(documentAction.setCorpState({ currentlySelectedCorpData: {} }));
      };

    useEffect(() => {
      if (agentType === "SELLER_AGENT" && state.isUseDetailModal) {
        dispatch(documentAction.setCorpState({ clientsArray: item?.sellerIds }));
      } else if (agentType === "BUYER_AGENT" && state.isUseDetailModal) {
        dispatch(documentAction.setCorpState({ clientsArray: item?.buyerIds }));
      }
    }, [item, state.isUseDetailModal, agentType]);
    const corpClients = clientsArray?.filter((ele) => ele.isCorp) || [];
    const nonCorporateClients = clientsArray?.filter((ele) => !ele.isCorp) || [];

    return (
      <>
        <Modal open={showClientListModal} onOk={() => {}} onCancel={handleCancelUserlist} footer={false} style={{ marginTop: "-50px" }}>
          <ClientList listSource={"offerSection"} selectClient={handleClientSelected} setShowClientListModal={setShowClientListModal} page={page} setPage={setPage} showSearchInput={showSearchInput} setShowSearchInput={setShowSearchInput} value={value} setValue={setValue} delegateOwnerId={delegateOwnerId} isfilterArchive={isfilterArchive} setIsFilterArchive={setIsFilterArchive} />
        </Modal>
        <Modal title="" visible={state.isUseDetailModal} onCancel={handleCancel} footer={false}>
          {/* create new customer */}
          <CreateCustomerDrawer visible={drawerVisible} onClose={drawerClose} nameData="customer" delegateOwnerId={delegateOwnerId} />
          <div className={styles.contentDiv}>
            <div className={styles.personheading}>
              <text>Persons</text>
              <PlusCircleFilled
                style={{
                  fontSize: "22px",
                  fill: "#085191",
                  color: "#085191",
                 
                }}
                onClick={() => {
                  setFormVisibility((prevState) => ({
                    ...prevState,
                    addPersons: !prevState.addPersons, // Toggle the 'signers' value
                  }));
                }}
              />
            </div>
            <div style={{ width: "100%" }}>
              {formVisibility?.addPersons && sourceConditon !== "NO_EDIT" ? (
                <Form.Item name="buyer">
                  <div style={{ display: "flex", gap: "10px", width: "100%", marginTop: "10px" }}>
                    <Input placeholder={`Select a person`} onClick={handleInputOrSearchClick} />
                    <Tooltip placement="top" title="Create customer">
                      <UserAddOutlined
                        onClick={handleCreateUserClick}
                        style={{
                          fontSize: "18px",
                          color: "#5959dcd9",
                          marginTop: "5px",
                        }}
                      />
                    </Tooltip>

                    <Button onClick={handleInputOrSearchClick}>
                      <SearchOutlined style={{ fontSize: "18px" }} />
                    </Button>
                  </div>
                </Form.Item>
              ) : null}

                {nonCorporateClients.length > 0 ?  (
                <Form.Item>
                  <div className={styles.selectedRowsOuterDiv}>
                    <List
                      style={{
                        margin: isMobile ? "0px 0px" : "0px 0px",
                        width: isMobile ? "85%" : "89%",
                      }}
                      dataSource={clientsArray.filter((ele) => !ele.isCorp)}
                      renderItem={(item) => {
                        const intials = generateInitialsFromObj(item);
                        return (
                          <List.Item key={item?._id}>
                            <List.Item.Meta
                              avatar={
                                intials ? (
                                  <Avatar size={45} style={{ background: "#085191" }}>
                                    {intials}
                                  </Avatar>
                                ) : (
                                  <Avatar size={45} icon={<UserOutlined />} style={{ background: "#085191" }} />
                                )
                              }
                              title={
                                <span
                                  style={{
                                    fontSize: "15px",
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                    lineHeight: "45px",
                                  }}
                                >
                                  {item.fullName}
                                </span>
                              }
                            />
                            {sourceConditon === "NO_EDIT" ? null : (
                              <span onClick={() => handleDeleteClientOrCorp(item, "CLIENT")} style={{ cursor: "pointer" }}>
                                <DeleteTwoTone style={{ fontSize: "medium" }} />
                              </span>
                            )}
                          </List.Item>
                        );
                      }}
                    />
                  </div>
                </Form.Item>
              ) : (
               
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <EmptyData />
                  </div>
              
              )}
            </div>

            <div className={styles.offerImportpersonheading}>
              <text>Corporation/Trust/Other</text>
              <PlusCircleFilled
                style={{
                  fontSize: "22px",
                  fill: "#085191",
                  color: "#085191",
                  marginLeft: "5px",
                }}
                onClick={() => {
                  setFormVisibility((prevState) => ({
                    ...prevState,
                    addCorps: !prevState.addCorps, // Toggle the 'signers' value
                  }));
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "stretch",
                overflow: "auto",
              }}
            >
              {sourceConditon === "NO_EDIT"
                ? null
                : formVisibility?.addCorps && (
                    <div style={{ display: "flex", gap: "10px", width: "100%",marginTop:"10px" }}>
                      <Input placeholder="Enter Corporation/Trust Name" onKeyPress={handleKeyPress} name="trustee" value={entityName || ""} onChange={(e) => handleEntityNameChange(e)} />
                      <Button type="primary" onClick={handleCorpAdd} disabled={!entityName}>
                        Add
                      </Button>
                    </div>
                  )}
              {corpClients && corpClients.length > 0 ? (
                clientsArray
                  .filter((ele) => ele.isCorp)
                  .map((ele, ind) => (
                    <div key={ind} style={{ marginTop: "10px" ,overflow:"hidden"}}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "20px",
                            width: "100%",
                          }}
                        >
                          <span style={{ fontSize: "18px" }}>{ele.fullName}</span>
                          </div>
                          {sourceConditon === "NO_EDIT" ? null : (
                            <>
                              <div style={{ marginRight: "15px" }}>
                                <Tooltip title="Add Signers">
                                  <PlusCircleFilled
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleAddSigners(ele);
                                      return;
                                    }}
                                    style={{
                                      fontSize: "20px",
                                      fill: "#085191",
                                      color: "#085191",
                                      marginTop: "6px",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                              <div>
                                <Tooltip title="Delete Corporation/Trust">
                                  <DeleteTwoTone
                                    style={{
                                      fontSize: "medium",
                                      marginTop: "8px",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteClientOrCorp(ele, "CORP");
                                      return;
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </>
                          )}
                     
                      </div>

                      <List
                        dataSource={ele.signers || []}
                        renderItem={(item) => {
                          const intials = generateInitialsFromObj(item);

                          //   <List.Item
                          //     key={item?._id}
                          //     style={{
                          //       margin: isMobile ? "-10px 20px" : "-10px 7%",
                          //       width: isMobile ? "93%" : "90%",
                          //     }}
                          //   >
                          //     <List.Item.Meta
                          //       avatar={
                          //         intials ? (
                          //           <Avatar
                          //             size={45}
                          //             style={{ background: "#085191" }}
                          //           >
                          //             {intials}
                          //           </Avatar>
                          //         ) : (
                          //           <Avatar
                          //             size={45}
                          //             icon={<UserOutlined />}
                          //             style={{ background: "#085191" }}
                          //           />
                          //         )
                          //       }
                          //       title={
                          //         <span
                          //           style={{
                          //             fontSize: "15px",
                          //             display: "inline-block",
                          //             verticalAlign: "middle",
                          //             lineHeight: "45px",
                          //           }}
                          //         >
                          //           {item.fullName}
                          //         </span>
                          //       }
                          //     />
                          //     {sourceConditon === "NO_EDIT" ? null : (
                          //       <span
                          //         onClick={() =>
                          //           handleDeleteClientOrCorp(
                          //             ele,
                          //             "SIGNERS",
                          //             item,
                          //           )
                          //         }
                          //         style={{
                          //           cursor: "pointer",
                          //           display: "inline-block",
                          //           verticalAlign: "middle",
                          //         }}
                          //       >
                          //         <DeleteTwoTone
                          //           style={{ fontSize: "medium" }}
                          //         />
                          //       </span>
                          //     )}
                          //   </List.Item>
                          // );
                          return <EachCorpSigner key={item?._id} item={item} ele={ele} intials={intials} isMobile={isMobile} handleDelete={handleDeleteClientOrCorp} clientsArray={clientsArray} source={"SIGNER_MODAL"} />;
                        }}
                      />
                    </div>
                  ))):(
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center",marginTop:isMobile?"50px":0 }}>
                                <EmptyData />
                              </div>
                  )}
            </div>
          </div>
          {/* Save Cancel Buttons  */}
          {(item?.isProperty) &&
          !item?.persistId ? (
            <div style={{ display: "flex", justifyContent: "center", gap: "4%" ,marginTop:"20px"}}>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" onClick={handleAddSellers}>
                Save
              </Button>
            </div>
          ) : null}
        </Modal>
      </>
    );
  };

  export default UserDetailsModal;
