import { transactionDefaultSortFilter } from "../../Common/commondata/commonData";
import { PropertyConstants } from "./action-types";

const initialState = {
  propertyData: [],
  tasksData: [],
  currentlySelectedData: [],
  propertyDataLoading: false,
  propertyDataError: false,

  propertyDocs: [],
  folderOpenState: {},
  propertDocsError: false,
  propertyDataErrorMsg: null,
  propertDocsLoading: false,

  propertyDocsWithoutSection: [],
  propertDocsWithoutSectionError: null,
  propertyDataWithoutSectionErrorMsg: null,
  propertDocsWithoutSectionLoading: false,

  doraDocsUrlShorthandData: null,
  doraDocsUrlShorthandLoading: false,
  doraDocsUrlShorthandFailure: null,

  getPreDoraDocumentsData: [],
  getPreDoraDocumentsLoading: false,
  getPreDoraDocumentsError: null,

  needToKnowLoading: false,
  needToKnowData: null,
  needToKnowError: false,
  needToKnowErrorMsg: null,

  getPropertyLicenseLoading: false,
  getPropertyLicenseData: null,
  getPrpertyLicenseError: false,
  getPrpertyLicenseErrorMsg: null,

  updateNeedToKnow: [],

  linkingMlsLoading: false,
  linkingMlsData: [],
  linkingMlsError: null,
  linkingMlsErrorMsg: null,

  deLinkingMlsLoading: false,
  deLinkingMlsData: [],
  deLinkingMlsError: false,

  urlShorthandData: [],
  urlShorthandLoading: false,
  urlShorthandError: null,

  PropertyImageData: [],
  PropertyImageDataLoading: false,
  propetyImageDataError: null,

  updatedPropertyDoc: [],

  updatedPropertTask: [],

  filterSort: { ...transactionDefaultSortFilter },
  uploadedPdfDocSuccess: [],

  agentDetailsLoading: false,
  agentDetailsArray: [],
  clearForm: [],

  ownersInfoLoading: false,
  ownersInfo: [],
  ownersInfoError: false,

  propertyFolderData: [],

  moveToFolderData: [],
  moveToFolderDataLoading: false,
  moveToFolderDataError: false,
};

const PropertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case PropertyConstants.GET_PROPERTY_DATA_LOADING:
      return {
        ...state,
        propertyDataLoading: true,
        propertyDataError: false,
        propertyData: action?.pageNumber === 1 ? [] : [...state.propertyData],
      };
    case PropertyConstants.GET_PROPERTY_DATA_SUCCESS:
      return {
        ...state,
        propertyData: [...state.propertyData, ...action?.tcData],
        propertyDataLoading: false,
        propertyDataError: false,
      };
    case PropertyConstants.GET_PROPERTY_DATA_ERROR:
      return {
        ...state,
        propertyDataError: true,
        propertyDataErrorMsg: action.getTcDataError,
        propertyDataLoading: false,
      };
    case PropertyConstants.GET_SEARCH_PROPERTY_DATA_LOADING:
      return {
        ...state,
        propertyData: [],
        propertyDataLoading: true,
        propertyDataError: null,
      };
    case PropertyConstants.GET_SEARCH_PROPERTY_DATA_SUCCESS:
      return {
        ...state,
        propertyData: action?.searchData,
        propertyDataLoading: false,
        propertyDataError: null,
      };
    case PropertyConstants.GET_SEARCH_PROPERTY_DATA_ERROR:
      return {
        ...state,
        propertyDataError: true,
      };
    case PropertyConstants.SET_SELECTED_PROPERTY_DATA:
      return {
        ...state,
        currentlySelectedData: action.selectedData,
      };
    // property docs methods
    case PropertyConstants.GET_CLIENT_DOC_LOADING:
      return {
        ...state,
        propertDocsLoading: true,
        propertDocsError: null,
        propertyDocs: [],
      };
    case PropertyConstants.GET_CLIENT_DOC_SUCCESS:
      return {
        ...state,
        propertyDocs: action.propertyDocs,
        propertDocsLoading: false,
        propertDocsError: null,
      };
    case PropertyConstants.GET_CLIENT_DOC_ERROR:
      return {
        ...state,
        propertyDocs: [],
        propertDocsLoading: false,
        propertDocsError: null,
      };

    case PropertyConstants.RESET_PROPERTY_DOCS:
      return {
        ...state,
        propertyDocs: [],
        propertyDocsWithoutSection: [],
        propertDocsLoading: false,
        propertDocsError: null,
      };
    case PropertyConstants.GET_PROPERTY_DOC_WITHOUT_SECTION_LOADING:
      return {
        ...state,
        propertDocsWithoutSectionLoading: true,
        propertDocsWithoutSectionError: null,
        propertyDocsWithoutSection: [],
      };
    case PropertyConstants.GET_PROPERTY_DOC_WITHOUT_SECTION_SUCCESS:
      return {
        ...state,
        propertyDocsWithoutSection: action.propertyDocsWithoutSection,
        propertDocsWithoutSectionLoading: false,
        propertDocsWithoutSectionError: null,
      };
    case PropertyConstants.GET_PROPERTY_DOC_WITHOUT_SECTION_ERROR:
      return {
        ...state,
        propertyDocsWithoutSection: [],
        propertDocsWithoutSectionLoading: false,
        propertDocsWithoutSectionError: action.propertDocsWithoutSectionError,
      };
    // Tasks Data
    case PropertyConstants.GET_TASKS_DATA_LOADING:
      return {
        ...state,
        getTasksDataLoading: true,
        // tasksData: null,
        getTasksDataError: false,
      };
    case PropertyConstants.GET_TASKS_DATA_SUCCESS:
      return {
        ...state,
        tasksData: action.getTasksData,
        getTasksDataLoading: false,
        getTasksDataError: false,
      };
    case PropertyConstants.GET_TASKS_DATA_ERROR:
      return {
        ...state,
        tasksData: [],
        getTasksDataLoading: false,
        getTasksDataError: true,
      };
    case PropertyConstants.SET_TASKS_LOADING:
      return {
        ...state,
        getTasksDataLoading: action.getTasksDataLoading,
      };

    case PropertyConstants.GET_PRE_DORA_DOCUMENTS_LOADING:
      return {
        ...state,
        getPreDoraDocumentsLoading: true,
        getPreDoraDocumentsError: null,
      };
    case PropertyConstants.GET_PRE_DORA_DOCUMENTS_SUCCESS:
      return {
        ...state,
        getPreDoraDocumentsData: action.getPreDoraDocumentsData,
        getPreDoraDocumentsLoading: false,
        getPreDoraDocumentsError: null,
      };
    case PropertyConstants.GET_PRE_DORA_DOCUMENTS_FAILURE:
      return {
        ...state,
        getPreDoraDocumentsLoading: false,
        getPreDoraDocumentsError: true,
      };

    case PropertyConstants.NEED_TO_KNOW_LOADING:
      return {
        ...state,
        needToKnowLoading: action.value,
        needToKnowError: false,
      };
    case PropertyConstants.NEED_TO_KNOW_SUCCESS:
      return {
        ...state,
        needToKnowData: action.NeedToKnowData,
        needToKnowLoading: false,
        needToKnowError: false,
      };
    case PropertyConstants.NEED_TO_KNOW_FAILURE:
      return {
        ...state,
        needToKnowLoading: false,
        needToKnowError: true,
        needToKnowErrorMsg: action.errorMsg,
      };
    case PropertyConstants.UPDATE_NEED_TO_KNOW:
      return {
        ...state,
        updateNeedToKnow: action.updateNeedToKnowData,
        needToKnowLoading: false,
        needToKnowError: false,
      };
    case PropertyConstants.GET_CONSTANTS_LOADING:
      return {
        ...state,
        getPropertyLicenseLoading: true,
        getPrpertyLicenseError: false,
      };
    case PropertyConstants.GET_CONSTANTS_SUCCESS:
      return {
        ...state,
        getPropertyLicenseData: action.getPropertyLicenseData,
        getPropertyLicenseLoading: false,
        getPrpertyLicenseError: false,
      };
    case PropertyConstants.GET_CONSTANTS_FAILURE:
      return {
        ...state,
        getPropertyLicenseLoading: false,
        getPrpertyLicenseError: action.getPrpertyLicenseError,
        getPrpertyLicenseErrorMsg: action.errorMsg,
      };

    case PropertyConstants.LINK_MLS_LOADING:
      return {
        ...state,
        linkingMlsLoading: true,
      };
    case PropertyConstants.LINK_MLS_SUCCESS:
      return {
        ...state,
        linkingMlsLoading: false,
        linkingMlsData: action.linkmlsData,
      };
    case PropertyConstants.LINK_MLS_ERROR:
      return {
        ...state,
        linkingMlsLoading: false,
        linkingMlsError: action.error,
        linkingMlsErrorMsg: action.errorMsg,
      };
    case PropertyConstants.DELINK_MLS_LOADING:
      return {
        ...state,
        deLinkingMlsLoading: true,
        deLinkingMlsError: false,
      };
    case PropertyConstants.DELINK_MLS_SUCCESS:
      return {
        ...state,
        deLinkingMlsLoading: false,
        deLinkingMlsError: false,
        deLinkingMlsData: [],
      };
    case PropertyConstants.DELINK_MLS_ERROR:
      return {
        ...state,
        deLinkingMlsLoading: false,
        deLinkingMlsError: true,
      };

    case PropertyConstants.SHORTHAND_URL_LOADING:
      return {
        ...state,
        urlShorthandLoading: true,
        urlShorthandError: null,
      };
    case PropertyConstants.SHORTHAND_URL_SUCCESS:
      return {
        ...state,
        urlShorthandData: action,
        urlShorthandLoading: false,
        urlShorthandError: null,
      };
    case PropertyConstants.SHORTHAND_URL_FAILURE:
      return {
        ...state,
        urlShorthandData: [],
        urlShorthandLoading: false,
        urlShorthandError: null,
      };
    // Property Images methods
    case PropertyConstants.POST_PROPERTY_IMAGE_LOADING:
      return {
        ...state,
        PropertyImageDataLoading: true,
        propetyImageDataError: null,
      };
    case PropertyConstants.POST_PROPERTY_IMAGE_SUCESS:
      return {
        ...state,
        PropertyImageData: [],
      };
    case PropertyConstants.POST_PROPERTY_IMAGE_ERROR:
      return {
        ...state,
        PropertyImageData: [],
        PropertyImageDataLoading: false,
        propetyImageDataError: action.propetyImageDataError,
      };
    case PropertyConstants.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        updatedPropertyDoc: [],
      };
    case PropertyConstants.TASK_UPADTED:
      return {
        ...state,
        updatedPropertTask: [],
      };

    //Change filter sort
    case PropertyConstants.CHANGE_FILTER_SORT:
      return {
        ...state,
        filterSort: action.filterSort,
        propertyData: [],
        ...(action.clearForm ? { clearForm: [] } : {}),
      };
    case PropertyConstants.RESET_FILTER_SEARCH:
      return {
        ...state,
        filterSort: transactionDefaultSortFilter,
      };
    case PropertyConstants.UPLOAD_DOCUMENT_PDF_SUCCESS:
      return {
        ...state,
        uploadedPdfDocSuccess: [],
      };
    case PropertyConstants.GET_AGENT_DETAILS_LOADING:
      return {
        ...state,
        agentDetailsLoading: action.agentDetailsLoading,
      };
    case PropertyConstants.GET_AGENT_DETAILS_SUCCESS:
      return {
        ...state,
        agentDetailsArray: action.agentDetailsArray,
        agentDetailsLoading: false,
      };
    case PropertyConstants.SET_AGENT_DETAILS_DATA:
      return {
        ...state,
        agentDetailsArray: action.agentDetailsArray,
      };
    case PropertyConstants.GET_OWNERS_INFO_LOADING:
      return {
        ...state,
        ownersInfoLoading: true,
        ownersInfoError: false,
      };
    case PropertyConstants.GET_OWNERS_INFO_SUCCESS:
      return {
        ...state,
        ownersInfo: action.ownersInfo,
        ownersInfoLoading: false,
        ownersInfoError: false,
      };
    case PropertyConstants.GET_OWNERS_INFO_ERROR:
      return {
        ...state,
        ownersInfoLoading: false,
        ownersInfoError: true,
      };
    case PropertyConstants.GET_PROPERTY_DOC_FOLDER_DATA_SUCCESS:
      return {
        ...state,
        propertyFolderData: action.propertyFolderData,
      };
    case PropertyConstants.GET_MOVE_TO_FOLDER_DATA_SUCCESS:
      return {
        ...state,
        moveToFolderData: action.moveToFolderData,
        moveToFolderDataLoading: false,
      };
    case PropertyConstants.GET_MOVE_TO_FOLDER_DATA_LOADING:
      return {
        ...state,
        moveToFolderData: [],
        moveToFolderDataLoading: true,
        moveToFolderDataError: false,
      };
    case PropertyConstants.GET_MOVE_TO_FOLDER_DATA_ERROR:
      return {
        ...state,
        moveToFolderData: [],
        moveToFolderDataLoading: false,
        moveToFolderDataError: true,
      };
    case PropertyConstants.TOGGLE_FOLDER_OPEN_STATE:
      return {
        ...state,
        folderOpenState: {
          ...state.folderOpenState,
          [action.folderId]: !state.folderOpenState[action.folderId],
        },
      };
    case PropertyConstants.RESET_FOLDER_OPEN_STATE:
      return {
        ...state,
        folderOpenState: {},
      };

    default:
      return state;
  }
};
export { PropertyReducer };
