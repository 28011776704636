import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../Common/components/Loading";
import { useMediaQuery } from "react-responsive";
import { message, Radio, Select, Spin } from "antd";
import { ERTSAction } from "../state/action";
import { useEffect } from "react";
import axios from "axios";

const CreateERTS = ({ setisModalOpen, next, importModalStep, setErtsType }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const dispatch = useDispatch();
  const { ERTS_Loading, checkIsMarkEibnerBrokerageError, MEBrokerageAccess, checkIsMarkEibnerBrokerageLoading } = useSelector((state) => state.ERTSReducer);

  const handleUploadClick = () => {
    setisModalOpen((prevState) => ({
      ...prevState,
      docsModal: true,
      importModalStep: 0,
    }));
  };

  let unmounted = {
    value: false
  };
  let sourceAxios = axios.CancelToken.source();

  useEffect(() => {
    dispatch(ERTSAction.checkIsMarkEibnerBrokerage({ unmounted, sourceAxios }));
    return () => {
      unmounted.value = true;
      sourceAxios.cancel("Cancelling in cleanup");
    }
  }, []);
  
  return ERTS_Loading ? (
    <div style={{ marginTop: "15%", height: isMobile ? "300px" : "500px" }}>
      <Loading />
    </div>
  ) : (
    <div
      style={
        isMobile && importModalStep === 1
          ? { marginTop: "-40px" }
          : { height: "100%", margin: "auto" }
      }
    >
        {checkIsMarkEibnerBrokerageError ? message.error(checkIsMarkEibnerBrokerageError) :
          checkIsMarkEibnerBrokerageLoading ? <div style={{textAlign: 'center'}}><Spin /></div> :
            MEBrokerageAccess && (
              <div style={{position: 'relative', border: '1px solid silver', borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <span style={{position: 'absolute', top: -12, left: 10, padding: '0 5px', backgroundColor: 'white', fontWeight: 'bold', color: "grey"}}>ERTS Type</span>
                <Radio.Group
                  style={{ margin: "10px" }}
                  onChange={(e) =>
                    setErtsType({
                      contractType: e.target.value,
                      contractId:
                        e.target.value === "ERTS"
                          ? "66aa0f8695a14f0a36fc6e12" // ERTS
                          : "678103bebb15f25706c38f74", // FRASCONA_ERTS
                    })
                  }
                  defaultValue="ERTS"
                >
                  <Radio value="ERTS" style={{ display: "block" }}>
                    DORA Exclusive Right to Sell
                  </Radio>
                  <Radio value="FRASCONA_ERTS" style={{ display: "block" }}>
                    Custom Exclusive Right to Sell
                  </Radio>
                </Radio.Group>
              </div>
            )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div
          onClick={() => {
            next();
          }}
        >
          <span
            style={{ color: "#2C59A9", fontSize: "25px", cursor: "pointer" }}
          >
            Create
          </span>
        </div>
        <div>
          <span style={{ color: "grey", fontSize: "15px" }}>
            ------ OR ------
          </span>
        </div>
        <div onClick={handleUploadClick}>
          <span
            style={{
              color: "#2C59A9",
              fontSize: "22px",
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>Upload</div>
            <div style={{ fontSize: "18px", textAlign: "center" }}>
              (Existing PDF of Exclusive Right To Sell Contract)
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export { CreateERTS };

