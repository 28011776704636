import { checkForDeleteClientDoc } from "../../Client/utils/helper";
import { conterOfferContractTypes, createOfferContractTypes, isBuyingNotExecutedFunc, isSellingNotExecutedFunc } from "../../Common/commondata/commonData";
import { combineBrokersFirmName, formatPhoneNumber } from "../../Common/utils/extraFunctions";
import { getFullNamesFromArray } from "../../DocumentSection/utils/helper";

export const getCategoryDataHandle = ({ notificatonData }) => {
  if (notificatonData) {
    let { type, openFor, offerId, propertyId, _id } = notificatonData || {};
    let category, categoryId, status;
    if (openFor === "BUYER_AGENT") {
      category = "BUYER";
      categoryId = offerId;
      status = type === "OFFER_TERMINATED" ? "TERMINATED" : "";
    } else {
      category = "SELLER";
      categoryId = propertyId ? propertyId : _id;
    }

    return { category, categoryId, status };
  } else {
    return { category: null, categoryId: null, status: null };
  }
};

// export const isDisclosureDocPresent = (propertyUploadDocs) => {
//   return propertyUploadDocs && propertyUploadDocs.some(element =>
//     element.offerDocument &&
//     !element?.offerId &&
//     !["ERTS", "LCAES", "FRASCONA_ERTS"].includes(element.contractType)
//   );
// }

const mapSigners = (coClient, role) =>
  coClient.signers?.map((signer) => ({
    ...signer,
    corpKey: coClient.key,
    openFor: role,
    isCorp: true,
  })) || [];

export const mapClientsForSendEmail = ({ clients, role, agents }) => {
  const mergedClients = [...(clients ? clients : []), ...(agents ? agents : [])];
  return (
    mergedClients?.map((coClient) => ({
      ...coClient,
      openFor: coClient.isAgent ? coClient.openFor : role,
      isReadOnly: false,
      isAddedByUser: false,
      ...(coClient.isCorp
        ? {
            signers: mapSigners(coClient, role),
            isCorp: true,
          }
        : { isCorp: false }),
    })) || []
  );
};

export const getBuildDataForCustomerdocs = ({ contractType, contractId, currentAgentDetails, delegate, transactionData, item, officeDetails }) => {
  let BuyerAgents = [],
    SellerAgents = [];
  let Buyers = [];
  let Sellers = [];

  if (["ERTB", "BDB", "FRASCONA_BDB"].includes(contractType)) {
    Buyers = item?.isErts || item?.isProperty ? transactionData?.Sellers : transactionData?.Buyers;

    BuyerAgents.push({
      personId: currentAgentDetails?._id,
      fullName: currentAgentDetails?.fullName,
      email: currentAgentDetails?.email,
      firmName: currentAgentDetails?.brokerageData?.name,
      resureFirmId: currentAgentDetails?.brokerageData?.resureFirmId,
      firmLicense: currentAgentDetails?.brokerageData?.licenseNumber,
      license: currentAgentDetails.licenseId,
      address: currentAgentDetails?.brokerageData?.address || "",
      phoneNumber: formatPhoneNumber(currentAgentDetails?.mobilePhoneNumber?.phoneNumber),
      faxNumber: formatPhoneNumber(currentAgentDetails?.brokerageData?.faxNumber, "FAX") || "",
    });
  } else {
    Sellers = item?.isErts || item?.isProperty ? transactionData?.Sellers : transactionData?.Buyers;
    SellerAgents.push({
      personId: currentAgentDetails?._id,
      fullName: currentAgentDetails?.fullName,
      email: currentAgentDetails?.email,
      resureFirmId: currentAgentDetails?.brokerageData?.resureFirmId,
      firmName: currentAgentDetails?.brokerageData?.name,
      firmLicense: currentAgentDetails?.brokerageData?.licenseNumber,
      license: currentAgentDetails.licenseId,
      address: currentAgentDetails?.brokerageData?.address || "",
      phoneNumber: formatPhoneNumber(currentAgentDetails?.mobilePhoneNumber?.phoneNumber),
      faxNumber: formatPhoneNumber(currentAgentDetails?.brokerageData?.brokerageFaxNumber, "FAX") || "",
    });
  }

  let buildData = {
    urlData: {
      ...(["ERTB", "BDB", "FRASCONA_BDB"].includes(contractType) && Buyers?.length ? { Buyers } : Sellers?.length ? { Sellers } : {}),
      ...(BuyerAgents.length > 0 ? { BuyerAgents, BuyerBrokersList: getFullNamesFromArray(BuyerAgents), BuyerBrokersFirmList: combineBrokersFirmName(BuyerAgents) } : {}),
      ...(SellerAgents.length > 0 ? { SellerAgents, SellerBrokersList: getFullNamesFromArray(SellerAgents), SellerBrokersFirmList: combineBrokersFirmName(SellerAgents) } : {}),
      officeDetails,
    },
    openFor: ["ERTB", "BDB", "FRASCONA_BDB"].includes(contractType) ? "BUYER_AGENT" : "SELLER_AGENT",
    RTDInitiator: ["ERTB", "BDB", "FRASCONA_BDB"].includes(contractType) ? "BUYER" : "SELLER",
    ...(delegate ? { delegateOwnerId: currentAgentDetails?._id } : {}),
    contractId: contractId,
    contractType: contractType,
    token: localStorage.getItem("userAuth"),
    builtForSection: "DOCUMENT",
    builtForId: currentAgentDetails?._id,
    key: currentAgentDetails?._id,
    signable: true,
  };
  return buildData;
};

export const canPropertyDocItemBeDeleted = ({ eachDoc, getCoordinateItem }) => {
  if (eachDoc?.isCustomerDoc && !checkForDeleteClientDoc(eachDoc)) {
    return false;
  }
  const isSellingNotExecuted = isSellingNotExecutedFunc({item: getCoordinateItem});
  if (["ERTS", "FRASCONA_ERTS"].includes(eachDoc?.contractType) && getCoordinateItem?.isProperty && isSellingNotExecuted) {
    return false;
  }
  const isbuyingNotExecuted = isBuyingNotExecutedFunc({item: getCoordinateItem});
  if (createOfferContractTypes.includes(eachDoc?.contractType) && isbuyingNotExecuted) {
    return false;
  }
  if (conterOfferContractTypes.includes(eachDoc?.contractType) && eachDoc?.buyerSignStatus === "SUBMITTED" && eachDoc?.sellerSignStatus === "SUBMITTED") {
    return false;
  }
  return true;
};
