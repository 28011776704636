/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import styled from "styled-components";
import { Col, Row, message } from "antd";
import { FolderFilled, FolderOpenOutlined, CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Loading } from "../../Common/components/Loading";
import { PropertyHandlers } from "../state/actions";
import { useDispatch } from "react-redux";

const StyledTree = styled.div`
  line-height: 1.5;
`;
const StyledFolder = styled.div`
  padding-left: 0px;
  margin-left: ${(props) => (props.level > 1 ? "30px" : "0px")};
  .folder--label {
    display: flex;
    align-items: center;
    span {
      margin-left: 0px;
    }
`;
const Collapsible = styled.div`
  height: ${(p) => (p.isOpen ? "0" : "auto")};
  overflow: hidden;
`;

const ArrowIcon = styled.span`
  cursor: pointer;
  padding: 5px;
  display: inline-flex;
  align-items: center;
`;

const FolderContent = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

const Folder = ({ name, children, id, selectedFiles, setMoveFolderModalOpen, setSelectedFiles, level, fixedOption, getCoordinateItem }) => {
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();

  const handleToggle = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleCancel = () => {
    setMoveFolderModalOpen(false);
    setSelectedFiles({ ...selectedFiles, offerDocs: [], propertyDocs: [] });
  };
//   console.log("selectedFiles+++++", selectedFiles, "getCoordinateItem", getCoordinateItem,"getCoordinateItem?.isProperty", getCoordinateItem?.isProperty || getCoordinateItem?.isErts);
  const handleMoveToFolder = (fixedOption) => {
    const params = {
      ...(selectedFiles?.propertyDocs?.length ? { propertyDocsIds: selectedFiles?.propertyDocs } : {}),
      ...(selectedFiles?.offerDocs?.length ? { offerDocsIds: selectedFiles?.offerDocs } : {}),
      folderId: id,
      storageType: "FILE",
      folderAction: fixedOption ? "REMOVE" : "ADD",
      openFor: getCoordinateItem?.isProperty || getCoordinateItem?.isErts ? "SELLER_AGENT" : "BUYER_AGENT",
    };
    if (selectedFiles?.offerDocs?.length || selectedFiles?.propertyDocs?.length) {
      dispatch(PropertyHandlers.moveToFolderFun({ params }));
      handleCancel();
    } else {
      message.info("Please select one or more files to move into a folder.");
    }
  };

  return (
    <StyledFolder level={level}>
      <div className="folder--label">
        {!fixedOption && (
          <ArrowIcon onClick={handleToggle}>
            {isOpen ? (
              <CaretRightOutlined style={{ fontSize: "1.3rem", color: "#8C8C8C" }} />
            ) : (
              <CaretDownOutlined
                style={{
                  fontSize: "1.3rem",
                  color: "rgb(107,152,199)",
                }}
              />
            )}
          </ArrowIcon>
        )}
        <FolderContent onClick={() => handleMoveToFolder(fixedOption)} onMouseEnter={(e) => (e.currentTarget.querySelector(".folder_name").style.color = "#178dfa")} onMouseLeave={(e) => (e.currentTarget.querySelector(".folder_name").style.color = "#9c9c9c")} style={{ cursor: "pointer" }}>
          {isOpen ? <FolderFilled style={{ fontSize: "20px", marginTop: "3px", color: "rgb(107,152,199)" }} /> : <FolderOpenOutlined style={{ fontSize: "20px", marginTop: "3px", color: "rgb(107,152,199)" }} />}
          <span
            className="folder_name"
            style={{
              fontSize: "19px",
              fontWeight: 600,
              color: "#8C8C8C",
              marginTop: "4px",
              paddingLeft: "4px",
            }}
          >
            {name}
          </span>
        </FolderContent>
      </div>
      <Collapsible isOpen={isOpen}>{React.Children.map(children, (child) => (React.isValidElement(child) ? React.cloneElement(child, { level: level + 1 }) : child))}</Collapsible>
    </StyledFolder>
  );
};

const Tree = ({ moveToFolderData, selectedFiles, setMoveFolderModalOpen, setSelectedFiles, getCoordinateItem }) => {
  const renderTree = (items, level = 1) => {
    return items?.map((element) => (
      <Folder key={element?._id} name={element?.name} id={element?._id} selectedFiles={selectedFiles} setMoveFolderModalOpen={setMoveFolderModalOpen} setSelectedFiles={setSelectedFiles} level={level} getCoordinateItem={getCoordinateItem} >
        {element?.subItems && element?.subItems?.length > 0 && renderTree(element?.subItems)}
      </Folder>
    ));
  };

  return (
    <StyledTree>
      <Folder name="Property Docs" id={null} selectedFiles={selectedFiles} setMoveFolderModalOpen={setMoveFolderModalOpen} setSelectedFiles={setSelectedFiles} level={1} fixedOption={true} getCoordinateItem={getCoordinateItem} />
      {renderTree(moveToFolderData)}
    </StyledTree>
  );
};

export default function MoveToFolder({ moveToFolderData, moveToFolderDataLoading, selectedFiles, setMoveFolderModalOpen, setSelectedFiles, getCoordinateItem }) {

    console.log("getCoordinateItem", getCoordinateItem);
  return (
    <div
      style={{
        height: "calc(60vh - 8rem)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {moveToFolderDataLoading ? (
        <Loading />
      ) : (
        <Row
          span={24}
          style={{
            height: "50vh",
            overflowY: "auto",
            marginBottom: "5px",
          }}
        >
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tree moveToFolderData={moveToFolderData} selectedFiles={selectedFiles} setMoveFolderModalOpen={setMoveFolderModalOpen} setSelectedFiles={setSelectedFiles} getCoordinateItem={getCoordinateItem} />
          </Col>
        </Row>
      )}
    </div>
  );
}

Tree.Folder = Folder;
