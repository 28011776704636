import React from "react";
import { Loading } from "../../Common/components/Loading";
import { Button, Form, Input, Typography, Select, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { documentAction } from "../state/actions";
import "../style/DocumentForm.css";
import { useMediaQuery } from "react-responsive";

const { Title } = Typography;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const PropertySearchModal = ({  skip, setSkip, limit, setAddressData, form }) => {
  const { getPropertySearchLoading } = useSelector((state) => state.documentSection.PropertySearchReducer);
  const { demoMode } = useSelector((state) => state.app);
  // const [form] = Form.useForm();

  const [toggleId, setToggleId] = useState(form?.getFieldValue("listingId")?.length ? form?.getFieldValue("listingId") : null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const CustomImage = ({ src, alt, style }) => {
    const [loaded, setLoaded] = useState(false);

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {!loaded && <span>{alt}</span>}
        <Image preview={false} src={src} alt={alt} style={loaded ? style : { display: "none" }} onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} placeholder={<span>{alt}</span>} />
      </div>
    );
  };

  const propertySelectOption = [
    { value: "PRIVATE", label: "RE-Sure" },
    { value: "RECOLORADO", label: <CustomImage style={{ width: "110px", height: "35px" }} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage34369" alt="REcolorado" /> },
    { value: "IRES", label: <CustomImage style={{ width: "100px" }} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage84057" alt="IRES" /> },
    { value: "PPAR", label: <CustomImage style={{ width: "85px", height: "25px" }} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/657ff2157e660a0701cfaa24/MEMORIES/19PPMLS-logo-MAIN-WebRGB.jpeg" alt="PPAR" /> },
    { value: "PAR", label: <CustomImage style={{ width: "85px", height: "30px" }} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/qa/6444b9c521382a6780572f63/MEMORIES/2pastedImage68271" alt="PAR" /> },
    { value: "GJARA", label: <CustomImage style={{ width: "85px", height: "30px" }} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/prod/64cb4128b00a232d9c2cc0d4/MEMORIES/29gjara-logo.png" alt="GJARA" /> },
  ];

  let formHandleSubmit = (data) => {
    const trimmedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (typeof value === "string") {
          return [key, value?.trim()];
        } else if (typeof value === "number") {
          return [key, parseFloat(String(value)?.trim())];
        }
        return [key, value];
      })
    );

    setAddressData(trimmedData);
    dispatch(documentAction.getPropertySearch(trimmedData, demoMode, skip, limit));
    // form.resetFields();
  };

  const handleChange = () => {
    setToggleId(null);
    form?.setFieldsValue({listingId:""})
  }

  return (
    <>
      <Title
        level={3}
        style={{
          textAlign: "center",
          marginTop: "10px",
          marginBottom: "1em",
        }}
      >
        Property Search
      </Title>
      <div
        style={{
          height: isMobile ? "" : "470px",
        }}
      >
        {getPropertySearchLoading ? (
          <Loading />
        ) : (
          <Form
            {...layout}
            form={form}
            className="formaccnt"
            name="nest-messages"
            onFinish={(data) => formHandleSubmit(data)}
            initialValues={{
              destination: "RE Colorado",
              autoSearch: "false",
              "street number": "",
              "street name": "",
            }}
            preserve={true}
            style={{ minHeight: "402px" }}
          >
            <Form.Item name="propertyType" label="Source" className="lbl" rules={[{ required: true, message: "Please Select MLS" }]}>
              <Select placeholder="Select MLS" showArrow mode="multiple" options={propertySelectOption} size="large" open={dropdownVisible} onSelect={() => setDropdownVisible(false)} onDropdownVisibleChange={(open) => setDropdownVisible(open)} />
            </Form.Item>
            {/* Legend */}
            <div
              style={{
                textAlign: "right",
                position: "absolute",
                top: "20px",
                right: "30px",
                width: "15%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                margin: "auto",
              }}
            >
              <h1 style={{ color: "red" }}>*</h1> <h4 style={{ lineHeight: "35px", color: "grey" }}>Required</h4>
            </div>
            <div>
              <Form.Item name="streetNumber" label="Street Number" className="lbl" rules={[{ required: toggleId ? false : true, message: "Please Enter Street Number" }]}>
                <Input placeholder="Please Enter Street Number" onChange={handleChange} />
              </Form.Item>
              <Form.Item name="streetName" label="Street Name" className="lbl" rules={[{ required: toggleId ? false : true, message: "Please Enter Street Name" }]}>
                <Input placeholder="Please Enter Street Name" onChange={handleChange} />
              </Form.Item>
              <Form.Item name="unit" label="Unit" className="lbl">
                <Input placeholder="Please Enter Unit" onChange={handleChange} />
              </Form.Item>

              <Form.Item className="lbl1" name="city" label="City">
                <Input placeholder="Please Enter City Name" onChange={handleChange} />
              </Form.Item>
              <h2 style={{ margin: "-15px 0 15px 0", textAlign: "center", color: "gray" }}>OR</h2>
              <Form.Item className="lbl" name="listingId" label="Listing Id" rules={[{ required: toggleId ? true : false, message: "Please Enter Listing Id" }]}>
                <Input style={{ width: "100%" }} placeholder="Please Enter Listing Id" onChange={(e) => {
                  setToggleId(e)
                  form?.setFieldsValue({streetNumber:"", streetName:"", unit:"", city:""})
                  }} />
              </Form.Item>
            </div>
            <Form.Item label=" " colon={false} style={{ marginBottom: "25px", marginTop: "15px" }}>
              <Button type="primary" htmlType="submit" style={{ width: isMobile ? "100%" : "50%" }} onClick={() => setSkip(0)}>
                Search
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </>
  );
};

export { PropertySearchModal };
